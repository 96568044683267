import React, { Component } from "react";
import { withAuthorization } from "../../Session";
import { withFirebase } from "../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import NavigationMenus from "../../NavigationMenus";
import OverlayAlertBox from "../OverlayAlertBox";
import ModalAlert from "../ModalAlert";
import {
  Breadcrumbs,
  Chip,
  Typography,
  Link,
  Grid,
  Paper,
  Button,
  ButtonBase,
  LinearProgress,
  makeStyles,
  withStyles,
  StepConnector,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Divider,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Hidden,
  Snackbar,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Switch,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import * as ROUTES from "../../../constants/route";
import { AttachFileOutlined } from "@material-ui/icons";
import { AuthUserContext } from "../../Session";
import axios from "axios";
import * as uuid from "uuid";
import { withCookies } from "react-cookie";
import Helpers from "../../Helpers";
import { orange } from "@material-ui/core/colors";
import clsx from "clsx";
import { DropzoneAreaBase } from "material-ui-dropzone";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import NumberFormat from "react-number-format";
import CloseIcon from '@material-ui/icons/Close';

import PaymentImgWeb from "../../../images/payment-popup-web.png";
import PaymentImgMob from "../../../images/payment-popup-mobile.png";

import paymentOptionsData from "./payment_options";

import BPI from "../../../assets/paymentOptions/bpi-logo.png";
import UBQR from "../../../assets/QRCodes/UBQR.png";
import { Alert } from "@material-ui/lab";
import CopyUserId from "./Copyhelper";

import GetAppIcon from '@material-ui/icons/GetApp';

const InvestmentConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#C8932A",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#C8932A",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.getContrastText(orange[900]),
    backgroundColor: "#C8932A",
    fontFamily: "Poppins",
    fontSize: "13px",
    padding: "10px 45px",
    "&:hover": {
      backgroundColor: "#b8892e",
    },
  },
  breakdownText: {
    fontSize: "medium",
  },
  breakdownTextBold: {
    fontWeight: "bold",
  },
  dropzoneStyle: {
    minHeight: "0px",
  },
  dropzoneIcon: {
    display: "none",
  },
  highlightedArea: {
    backgroundColor: "#C8932A",
    color: theme.palette.getContrastText(orange[900]),
  },
  paymentClickable: {
    padding: "5px",
    width: "100%",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#EBBC5F",
      cursor: "pointer",
    },
  },
  iconSize: {
    height: "auto",
    width: "100px",
  },
  iconSizeSmall: {
    height: "auto",
    width: "50px",
    marginRight: "10px",
  },
  qrClass: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    height: "auto",
  },
  debit: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#EBBC5F",
      cursor: "pointer",
      color: "white",
    },
  },
  modalContent: {
    position: 'relative',
    backgroundColor: '#246040',
    padding: theme.spacing(2),
    color: '#ffffff',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: '#ffffff',
  },
  wavyContainer: {
    position: 'relative',
    backgroundColor: '#fff',
    borderRadius: '10px',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: 'url("/path-to-your-wavy-border.svg")', // Add wavy background image
      backgroundSize: 'cover',
      zIndex: -1,
    },
  },
  textContainer: {
    padding: theme.spacing(4),
    backgroundColor: '#2D6A4F', // Dark green for text area
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  imageContainer: {
    backgroundImage: 'url("/path-to-your-image.jpg")', // Image for the modal
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px',
  },
}));

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#C8932A",
      },
      "&:hover fieldset": {
        borderColor: "#ebab2d",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b88c35",
      },
    },
  },
})(TextField);

const PaymentPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <NavigationMenus
        disableFab={true}
        content={
          <Payment
            classes={classes}
            isMobile={isMobile}
          />
        }
      />
    </>
  );
};

class PaymentBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      firstInvestment: false,
      amount: "",
      fee: 10.0,
      picture: null,
      slip: [],
      pictureSecondary: null,
      slipSecondary: [],
      hasLongKYC: null,
      modalOpen: true,
      uuid: null,
      validated: null,
      docId: null,
      hasPending: false,
      investStep: 0,
      minAmount: 1000,
      maxRemaining: 500000,
      amountModalOpen: false,
      hasSelectedPayment: false,
      currentSelectedPayment: null,
      paymentChoices: paymentOptionsData,
      errorModal: false,
      additionalUpload: false,
      tooltipAccountTip: false,
      tooltipAccountNameTip: false,
      userAccNum: "",
      tier: null,
      initialDialogOpen: true,
    };
    this.helpers = new Helpers();
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    var uid = this.context.uid;
    var userRef = this.props.firebase.db.collection("users");
    var fundid = this.props.match.params.fundid; //params.fundid is the fund name
    var fundRef = this.props.firebase.db.collection("funds");
    let transRef = this.props.firebase.db.collection("transactions");
    let investmentsRef = this.props.firebase.db.collection("investments");
    let investmentsRecurringRef = this.props.firebase.db.collection(
      "investmentsRecurring"
    );
    let accountId;
    let tier;

    let hasValidID;

    userRef
      .doc(uid)
      .get()
      .then((result) => {
        accountId = result.data().accountNumber;
        tier = result.data().stats?.tier ?? 3;

        this.setState({
          userAccNum: accountId,
          tier,
          maxRemaining: tier <= 2 ? 50000 : 500000,
        });
        return userRef.doc(uid).collection("KYC").doc("KYC").get();
      })
      .then((result) => {
        if (result.data() !== undefined) {
          hasValidID = result.data().approvedTier2Id;
          if (
            result.data().validated === "pending" ||
            result.data().validated === "declined"
          ) {
            this.setState({ validated: false });

          } else {
            this.setState({ validated: true });

            userRef
              .doc(uid)
              .get()
              .then((result) => {
                if ("firstTimeInvestor" in result.data())
                  this.setState({
                    firstInvestment: result.data().firstTimeInvestor,
                  });

                if ("hasLongKYC" in result.data()) {
                  if (result.data().hasLongKYC) {
                    this.setState({
                      hasLongKYC: true,
                    });
                  } else {
                    this.setState({
                      hasLongKYC: false,
                    });
                  }
                } else {
                  this.setState({
                    hasLongKYC: false,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          this.setState({
            hasLongKYC: false,
          });
        }

        return (
          transRef
            .where("clientDetails.userUid", "==", uid)
            .where("status", "in", [
              "processing",
              "approved",
              "processingRedemption",
              "redeemed",
            ])
            // .where("status", "==", "processing")
            .get()
        );
      })
      .then((snapshot) => {
        let sum = 0;
        snapshot.forEach((doc) => {
          if (
            doc.data().fundDetails.fundName === fundid &&
            doc.data().status === "processing"
          ) {
            this.setState({ hasPending: true });
          }

          if (tier === 3) {
            if (doc.data().status === "processing") sum += doc.data().amount;
          } else {
            if (
              doc.data().status === "processing" ||
              doc.data().status === "approved"
            ) {
              if (doc.data().transactionType !== 'reinvestment') {
                sum += doc.data().amount;
              }
              else {
                sum += doc.data().value * doc.data().rateAtBooking
              }
            }
            else {
              sum -= doc.data().amount;
            }
          }
        });
        sum = this.state.maxRemaining - sum;
        this.setState({ maxRemaining: sum });

        return Promise.all([
          fundRef.where("name", "==", fundid).get(),

          investmentsRecurringRef
            .where("fundName", "==", fundid)
            .where(
              "transactionData.clientDetails.userAccountNumber",
              "==",
              accountId
            )
            .get(),
          investmentsRef
            .where("fundName", "==", fundid)
            .where("useruid", "==", accountId)
            .limit(1)
            .get(),
        ]);
      })
      .then((result) => {
        let minAmount = parseFloat(
          result[0].docs[0].data().minInitialInvestment
        );

        const requireValidId =
          result[0].docs[0].data().fundStats?.requiredValidId ?? false;


        if (requireValidId && !hasValidID && this.state.tier === 2) {
          alert(
            "This fund requires a valid TIN/SSS ID \nGet fully verified now!"
          );
          this.props.history.push(ROUTES.DASHBOARD);
          return;
        }

        if (result[2].size > 0)
          minAmount =
            result[2].docs[0].data().value > 0
              ? parseFloat(result[0].docs[0].data().minAdditionalInvestment)
              : parseFloat(result[0].docs[0].data().minInitialInvestment);

        this.setState({
          docId: result[0].docs[0].id,
          minAmount,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", this.handlePopState);
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.setState({ uuid: uuid.v4() });
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handlePopState);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    if (this.state.amount != 0) {
      const confirmationMessage =
        "You have unsaved changes. Are you sure you want to leave?";
      event.preventDefault();
      event.returnValue = confirmationMessage;
    }
  };

  handlePopState = () => {
    if (this.state.amount != 0) {
      const confirmationMessage =
        "You have unsaved changes. Are you sure you want to go back?";
      if (!window.confirm(confirmationMessage)) {
        window.history.pushState(null, null, window.location.href);
      } else {
        window.removeEventListener("popstate", this.handlePopState);
        window.history.back();
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.amount !== prevState.amount &&
      this.state.amount >= this.state.minAmount &&
      this.state.amount <= this.state.maxRemaining
    ) {
      const queryParams = new URLSearchParams(this.props.location.search);
      const currentStep = queryParams.get('step');

      if (!currentStep || currentStep === '1') {
        queryParams.set('step', 2);
        this.props.history.push({
          pathname: this.props.location.pathname,
          search: queryParams.toString(),
        });
      }
    }

    if (this.state.currentSelectedPayment !== prevState.currentSelectedPayment) {

      const queryParams = new URLSearchParams(this.props.location.search);
      const currentStep = queryParams.get('step');

      if (currentStep === '2') {
        queryParams.set('step', 3);
        this.props.history.push({
          pathname: this.props.location.pathname,
          search: queryParams.toString(),
        });
      }
    }
  }

  deletePicture = (secondary) => {
    if (!secondary)
      return this.setState({ picture: null, slip: [] }, () => {
        this.setInvestStep();
      });
    else return this.setState({ pictureSecondary: null, slipSecondary: [] });
  };

  modal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      hasLongKYC: null,
    });
    // var fundid = this.props.match.params.fundid;
    var fundid = this.state.docId;
    this.props.history.push(ROUTES.FUNDS + "/" + fundid + "");
  };

  handleChange = (event) => {
    if (event.target.value > 0)
      this.setState({ [event.target.name]: event.target.value }, () => {
        this.setInvestStep();
      });
  };

  setInvestStep = () => {
    let step = 0;
    if (this.state.amount !== "") step = 1;

    if (this.state.picture !== null) step = 2;
    else step = 1;

    this.setState({ investStep: step });
  };

  submit = (e) => {
    e.preventDefault();
    if (parseFloat(this.state.amount) === this.state.maxRemaining)
      this.setState({ amountModalOpen: true });
    else this.checkAmount(e);
  };

  checkAmount = (e) => {
    e.preventDefault();
    if (this.state.amount !== "") {
      this.setState({ loading: true });
      this.submitInvestment();
      this.setState({ amountModalOpen: false });
    } else {
      alert("No amount entered");
    }
  };

  submitInvestment = () => {
    const { cookies } = this.props;
    const token = cookies.get("token");
    var uid = this.context.uid;
    var fundName = this.props.match.params.fundid; //params.fundid is the fund name
    var json = {
      uuid: this.state.uuid,
      userUid: uid,
      fundId: this.state.docId,
      fundName: fundName,
      amount: this.state.amount,
    };
    const formData = new FormData();
    formData.append("req", JSON.stringify(json));
    formData.append("picture", this.state.picture);

    if (this.state.pictureSecondary !== null)
      formData.append("pictureSecondary", this.state.pictureSecondary);

    axios
      .post(
        `${ROUTES.FUNCTION_BASE_URL}investments-investments/createNewInvestment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.ok) {
          alert(res.data.message);
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false, success: true });
        }
      })
      .catch((err) => {
        this.helpers.handleError(err);
        console.log(err);
      });
  };

  getSteps = () => {
    return [
      "Enter amount to be invested",
      "Transfer payment",
      "Submit proof of payment",
    ];
  };

  handleDropzone = (files) => {
    let arr = [];
    arr.push(files[0]);
    this.setState({ picture: files[0].file, slip: arr }, () => {
      this.setInvestStep();
    });
  };

  handleDropzoneSecondary = (files) => {
    let arr = [];
    arr.push(files[0]);
    this.setState({ pictureSecondary: files[0].file, Secondary: arr });
  };

  selectPaymentOption = (e, index) => {
    e.preventDefault(e);
    let options = this.state.paymentChoices.payment_options;
    options.forEach((value) => {
      value.selected = false;
    });
    options[index].selected = true;

    this.setState({
      paymentChoices: { ...this.state.paymentChoices, options },
      hasSelectedPayment: true,
      currentSelectedPayment: options[index],
    });
  };

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="₱ "
      />
    );
  }

  getSelectedPaymentDescription = () => {
    const { classes } = this.props;
    return (
      <>
        <h3>{this.state.currentSelectedPayment.name}</h3>
        <Divider />
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={this.state.currentSelectedPayment.qrcode ? 10 : 12} lg={this.state.currentSelectedPayment.qrcode ? 10 : 12} xl={this.state.currentSelectedPayment.qrcode ? 10 : 12}>
            <p
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: this.state.currentSelectedPayment.details.description,
              }}
            />
            <p
              className="mx-5 text-sm"
              dangerouslySetInnerHTML={{
                __html: this.state.currentSelectedPayment.note,
              }}
            />
            <ul style={{ listStyleType: "none" }}>
              {this.state.currentSelectedPayment.details.steps.map((step) => (
                <li key={step.order}>
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ minWidth: "3.5rem", fontWeight: "bold" }}
                    >
                      Step{" "}
                      {this.state.currentSelectedPayment.details.steps.indexOf(
                        step
                      ) + 1}
                      :
                    </Typography>
                    <div style={{ maxWidth: "80ch" }}>
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{ __html: step.instruction }}
                      />
                    </div>
                  </div>
                  {step.instruction.includes("Bank Details") ? (
                    <ul style={{ listStyleType: "none", paddingLeft: "4em" }}>
                      {this.state.currentSelectedPayment.name === "GCash Bank Transfer"
                        &&
                        <li style={{ marginBottom: "-15px" }} >
                          <b>Account Name:</b> RAMPVER FINANCIALS INC
                          <CopyUserId acctNum="RAMPVER FINANCIALS INC" />
                        </li>}
                      <li>
                        <b>Account Number:</b> 002130009962{" "}
                        {this.state.currentSelectedPayment.details.description.includes(
                          "Unionbank App!"
                        ) && <CopyUserId acctNum="002130009962" />}
                        {this.state.currentSelectedPayment.name.includes(
                          "Bank via InstaPay"
                        ) && <CopyUserId acctNum="002130009962" />}
                        {this.state.currentSelectedPayment.name.includes(
                          "GCash Bank Transfer"
                        ) && <CopyUserId acctNum="002130009962" />}
                        {this.state.currentSelectedPayment.name.includes(
                          "Any Bank or E-Wallet via InstaPay"
                        ) && <CopyUserId acctNum="002130009962" />}
                        {this.state.currentSelectedPayment.name.includes(
                          "Any Bank via PESONet"
                        ) && <CopyUserId acctNum="002130009962" />}
                        {this.state.currentSelectedPayment.name.includes(
                          "Unionbank Over-The-counter (OTC)"
                        ) && <CopyUserId acctNum="002130009962" />}
                      </li>
                      {this.state.currentSelectedPayment.name !== "GCash Bank Transfer"
                        &&
                        <li style={{ marginTop: "-15px" }}>
                          <b>Account Name:</b> RAMPVER FINANCIALS INC
                          <CopyUserId acctNum="RAMPVER FINANCIALS INC" />
                        </li>}
                    </ul>
                  ) : (
                    step.instruction.includes(
                      "enter the numeric digits of your Rampver Online account number (RFXXXXXXXXXX) and click <b>Continue</b>"
                    ) && (
                      <div
                        style={{ listStyleType: "none", paddingLeft: "4em" }}
                      >
                        <CopyUserId
                          acctNum={this.state.userAccNum.slice(2, 100)}
                        />

                        <br />
                        <i>
                          Note: If your account number is RF<b>1234567890</b>,
                          your reference number is 1234567890 (total of 10
                          digits)
                        </i>
                        <br />
                        <br />
                      </div>
                    )
                  )}
                </li>
              ))}
              {this.state.currentSelectedPayment.sampleimg && (
                <>
                  <br />
                  <span className="ml-0 lg:ml-16 font-semibold text-lg">
                    Example:
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",

                    }}
                  >
                    <img
                      className="w-3/4 mr-8 lg:w-2/4 "
                      src={this.state.currentSelectedPayment.sampleimg}
                      alt="rampver-logo"
                    />
                  </div>
                </>
              )}
            </ul>
          </Grid>
          {this.state.currentSelectedPayment.qrcode && (
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              {console.log(this.state.currentSelectedPayment)}
              <div style={{ marginRight: "auto", marginLeft: "auto" }}>
                <img className={classes.qrClass} src={this.state.currentSelectedPayment.name === 'Unionbank App' ? this.state.currentSelectedPayment.qrCustom : UBQR} alt="UBQRcode" />
              </div>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                onClick={this.handleDownload}
              >
                Download QR
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  redirectAutoDebit = () => {
    const { fundid } = this.props.match.params;
    const { amount } = this.state;

    // Push both the URL and state
    this.props.history.push({
      pathname: `${ROUTES.RECURRING_INVESTMENT_INFO}`,
      search: `?fund=${fundid}&amount=${amount}`,
      state: {
        fromPayment: true,
        fundValue: fundid,
        investedValue: amount,
        minInitialValue: this.state.minAmount,
      },
    });
  };

  errorClose = () => {
    this.setState({ errorModal: false });
  };

  toggleSecondaryUpload = () => {
    this.setState({ additionalUpload: !this.state.additionalUpload });
  };

  handleDownload = () => {
    const imageSrc = this.state.currentSelectedPayment.name === 'Unionbank App'
      ? this.state.currentSelectedPayment.qrCustom
      : UBQR;

    const link = document.createElement('a');
    link.href = imageSrc;
    link.download = 'rampver-code.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { classes, isMobile } = this.props;
    document.body.style.backgroundColor = "white";
    let modal;
    let amountModal;
    const {
      success,
      amount,
      picture,
      loading,
      hasLongKYC,
      modalOpen,
      validated,
      docId,
      hasPending,
      investStep,
      slip,
      firstInvestment,
      minAmount,
      maxRemaining,
      amountModalOpen,
      paymentChoices,
      hasSelectedPayment,
      currentSelectedPayment,
      errorModal,
      additionalUpload,
      pictureSecondary,
      tier,
      initialDialogOpen,
    } = this.state;
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "PHP",
      minimumFractionDigits: 2,
    });

    if (tier === 1 && validated !== null && !validated) {
      modal = (
        <ModalAlert
          function={this.modal}
          open={modalOpen}
          message="Your application is still pending / was declined."
          validated={false}
          route={ROUTES.DASHBOARD}
        />
      );
    }
    if (hasLongKYC !== null && !hasLongKYC) {
      modal = (
        <ModalAlert
          function={this.modal}
          open={modalOpen}
          validate={true}
          message="You have not yet completed your Client Application, Would you like to complete it now?"
          route={ROUTES.LONG_KYC}
        />
      );
    }
    if (hasPending !== null && hasPending) {
      modal = (
        <ModalAlert
          function={this.modal}
          open={modalOpen}
          message="We are currently processing one of your transactions for this fund."
          validated={false}
          route={ROUTES.INVESTMENT_HISTORY}
        />
      );
    }
    const tieramount = tier === 3 ? "500,000" : "50,000";
    amountModal = (
      <Dialog
        open={amountModalOpen}
        onClose={() => {
          this.setState({ amountModalOpen: false });
        }}
      >
        <DialogContent>
          <DialogContentText style={{ color: "black" }}>
            You are investing PHP {tieramount} or the maximum daily amount to be
            invested.
          </DialogContentText>
          <br />
          <DialogContentText style={{ color: "black" }}>
            We will process your next investment after this initial investment
            is approved. Also, please note that PHP {tieramount} is the maximum
            amount to be redeemed per redemption request.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            autoFocus
            onClick={(e) => {
              this.checkAmount(e);
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    );
    const alertItem = (
      <Snackbar
        open={errorModal}
        onClose={this.errorClose}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert severity="error">
          The file you are trying to upload exceeds the file size limit
        </Alert>
      </Snackbar>
    );
    const paymentOptions = (
      <>
        <h3>Payment Options</h3>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <span>Choose one of the following:</span>
            <br />
          </Grid>
          {paymentChoices.payment_options.map((object, index) => (
            <>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Paper elevation={3} style={{ height: "100%" }}>
                  <ButtonBase
                    focusRipple
                    key={`payment-${index}`}
                    style={{ width: "100%", height: "100%" }}
                    onClick={(e) => this.selectPaymentOption(e, index)}
                    className={clsx(
                      classes.paymentClickable,
                      object.selected && classes.highlightedArea
                    )}
                  >
                    <Hidden smDown>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          className={classes.iconSize}
                          src={object.img}
                          alt="payment-icon"
                        />
                        <br />
                        <br />
                        <span
                          style={{
                            fontFamily: "Gill Sans, Poppins",
                            fontSize: "14px",
                          }}
                        >
                          {object.name}
                        </span>
                      </div>
                    </Hidden>
                    <Hidden mdUp>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className={classes.iconSizeSmall}
                          src={object.img}
                          alt="payment-icon"
                        />
                        <span
                          style={{
                            fontFamily: "Gill Sans, Poppins",
                            fontSize: "14px",
                          }}
                        >
                          {object.name}
                        </span>
                        {object.selected ? (
                          <ExpandMoreIcon
                            style={{
                              marginLeft: "auto",
                            }}
                          />
                        ) : (
                          <ChevronRightIcon
                            style={{
                              marginLeft: "auto",
                            }}
                          />
                        )}
                      </div>
                    </Hidden>
                  </ButtonBase>
                </Paper>
              </Grid>
              <Hidden mdUp>
                {hasSelectedPayment &&
                  object.selected === currentSelectedPayment.selected ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          height: "100%",
                          padding: "5px",
                        }}
                      >
                        {this.getSelectedPaymentDescription()}
                      </div>
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}
              </Hidden>
            </>
          ))}
          <Hidden smDown>
            {hasSelectedPayment ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: "10px",
                  }}
                >
                  <h4>
                    {currentSelectedPayment === null
                      ? ""
                      : currentSelectedPayment.label}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                      padding: "5px",
                    }}
                  >
                    {this.getSelectedPaymentDescription()}
                  </div>
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Hidden>
        </Grid>
      </>
    );

    let investDisable = true;
    if (picture !== null && amount <= maxRemaining && amount >= minAmount)
      investDisable = false;

    const initialDialogWeb = (
      <Dialog
        open={initialDialogOpen}
        onClose={() => this.setState({
          initialDialogOpen: false,
        })}
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: '#246040',
            borderRadius: '40px',
          }
        }}
      >
        <DialogContent style={{ padding: 0, backgroundColor: '#246040', }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            style={{
              backgroundColor: '#246040',
              padding: '20px 40px',
              color: 'white',
              position: 'relative'
            }}
          >
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              Friendly Reminder
            </Typography>
            <Typography variant="h4" style={{ margin: '15px 0', fontWeight: 700, width: '70%' }}>
              Don’t forget to upload your proof of transfer!
            </Typography>
          </Box>
          <Box style={{
            position: 'relative',
            marginTop: '-220px',
            padding: 0
          }}>
            <div style={{ position: 'relative' }}>
              <img
                src={PaymentImgWeb}
                alt="Payment Image"
                style={{
                  width: '100%',
                  marginBottom: '-5px'
                }}
              />
              <IconButton
                style={{
                  position: 'absolute',
                  top: '55px',
                  right: '8px',
                  cursor: 'pointer',
                  color: 'white',
                }}
                onClick={() => this.setState({
                  initialDialogOpen: false,
                })}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    )

    const initialDialogMobile = (
      <Dialog
        open={initialDialogOpen}
        onClose={() => this.setState({
          initialDialogOpen: false,
        })}
        maxWidth="xs"
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: '#246040',
            borderRadius: '40px',
          }
        }}
      >
        <DialogContent style={{ padding: 0, backgroundColor: '#246040', }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            style={{
              backgroundColor: '#246040',
              padding: '20px',
              color: 'white',
              position: 'relative'
            }}
          >
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              Friendly Reminder
            </Typography>
            <Typography
              variant="h6"
              style={{
                margin: '15px 0',
                fontWeight: 700,
                width: '80%',
              }}
            >
              Don’t forget to upload your proof of transfer!
            </Typography>
          </Box>
          <Box
            style={{
              position: 'relative',
              marginTop: '-150px',
              padding: 0,
            }}
          >
            <img
              src={PaymentImgMob}
              alt="Payment Image"
              style={{
                width: '100%',
                marginBottom: '-5px',
              }}
            />
            <IconButton
              style={{
                position: 'absolute',
                top: '5px',
                right: '8px',
                cursor: 'pointer',
                color: 'white',
              }}
              onClick={() => this.setState({
                initialDialogOpen: false,
              })}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    )

    return (
      <>
        {modal}
        {amountModal}
        {alertItem}
        {success ? <OverlayAlertBox firstInvestment={firstInvestment} /> : ""}
        {isMobile ? initialDialogMobile : initialDialogWeb}
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textSecondary" href={ROUTES.DASHBOARD}>
            Dashboard
          </Link>
          <Link color="textSecondary" href={ROUTES.MY_INVESTMENTS}>
            Funds
          </Link>
          <Link
            color="textSecondary"
            href={
              ROUTES.FUNDS + "/" + docId
              // this.props.match.params.fundid +
              // ""
            }
          // href={ROUTES.MY_INVESTMENTS}
          >
            {this.props.match.params.fundid}
          </Link>
          <Typography color="textPrimary" aria-current="page">
            Payment Details
          </Typography>
        </Breadcrumbs>

        <div className="body-content">
          <div className="payment-details">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h1>Payment Details</h1>
                <Stepper
                  activeStep={investStep}
                  connector={<InvestmentConnector />}
                  alternativeLabel
                >
                  {this.getSteps().map((label, index) => (
                    <Step key={`step-${index}`}>
                      <StepLabel style={{ color: "#C8932A" }}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={2} xl={2} />
              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Paper elevation={3} style={{ padding: "10px" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                      <h3>Enter Amount to be Invested:</h3>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      xl={4}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CustomTextField
                        className="input-php"
                        variant="outlined"
                        label="Amount to be Invested"
                        value={amount}
                        onChange={this.handleChange}
                        name="amount"
                        InputProps={{
                          style: { textAlign: "right" },
                          inputComponent: this.NumberFormatCustom,
                        }}
                      />
                    </Grid>
                  </Grid>
                  {amount !== "" ? (
                    <div>
                      {amount < minAmount || amount > maxRemaining ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <WarningRoundedIcon
                            style={{ color: "#EA3636", marginRight: "5px" }}
                          />
                          <span
                            style={{ fontStyle: "italic", color: "#EA3636" }}
                          >
                            Your investment amount must be between{" "}
                            <b>{formatter.format(minAmount)}</b> and{" "}
                            <b>{formatter.format(maxRemaining)}</b>
                          </span>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2} xl={2} />
            </Grid>
            {amount >= minAmount && amount <= maxRemaining && (
              <div>
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Paper elevation={3} style={{ padding: "10px" }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <h3>Transfer Payment</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <>
                            <span>
                              You can pay for your Rampver Online investment
                              through your Unionbank App, GCash, BPI, or any
                              bank that allows Instapay or PESONet transactions!
                            </span>
                            <br />
                            <br />
                          </>
                          <Paper
                            elevation={3}
                            className={classes.highlightedArea}
                            style={{ padding: "10px" }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span
                                  className={clsx(
                                    classes.breakdownText,
                                    classes.breakdownTextBold
                                  )}
                                >
                                  Total Amount to be Paid:
                                </span>
                              </Grid>
                              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span
                                  className={clsx(
                                    classes.breakdownText,
                                    classes.breakdownTextBold
                                  )}
                                  style={{ float: "right" }}
                                >
                                  {formatter.format(amount)}
                                </span>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                      {paymentOptions}
                      <br />
                      {/* {autoDebitBar} */}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2} />
                </Grid>
              </div>
            )}
            {investStep > 0 ? (
              <div>
                {currentSelectedPayment === null ? (
                  ""
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2} />
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                      <Paper elevation={3} style={{ padding: "10px" }}>
                        <h3>Submit Proof of Payment</h3>
                        <span style={{ fontStyle: "italic" }}>
                          *Maximum limit of 9 MB
                        </span>
                        <div className="upload">
                          <div className="upload-box">
                            <DropzoneAreaBase
                              classes={{
                                root: classes.dropzoneStyle,
                                icon: classes.dropzoneIcon,
                              }}
                              acceptedFiles={["image/*", "application/pdf"]}
                              maxFileSize={9500000}
                              filesLimit={1}
                              onDropRejected={() => {
                                this.setState({ errorModal: true });
                              }}
                              dropzoneText={
                                <p style={{ fontSize: "medium" }}>
                                  <b>
                                    Upload Payment Confirmation or Deposit Slip
                                    Here
                                  </b>
                                  <br />
                                  (Please ensure the confirmation number, total
                                  amount, and biller details are visible)
                                </p>
                              }
                              showPreviewsInDropzone={false}
                              showAlerts={false}
                              onAdd={(files) => this.handleDropzone(files)}
                              fileObjects={slip}
                            />
                          </div>
                          <br />
                          <Paper className="custom-list-upload" component="ul">
                            {picture !== null && (
                              <Chip
                                icon={<AttachFileOutlined />}
                                label={picture.name}
                                onDelete={() => this.deletePicture(false)}
                                className="custom-chip-upload"
                              />
                            )}
                          </Paper>
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                color="primary"
                                name="secondaryID"
                                checked={additionalUpload}
                                onClick={() => this.toggleSecondaryUpload()}
                              />
                            }
                            label="My payment confirmation details don't fit into a single screenshot"
                            labelPlacement="end"
                          />
                          {additionalUpload ? (
                            <>
                              <br />
                              <div className="upload-box">
                                <DropzoneAreaBase
                                  classes={{
                                    root: classes.dropzoneStyle,
                                    icon: classes.dropzoneIcon,
                                  }}
                                  acceptedFiles={["image/*", "application/pdf"]}
                                  maxFileSize={9500000}
                                  filesLimit={1}
                                  onDropRejected={() => {
                                    this.setState({ errorModal: true });
                                  }}
                                  dropzoneText={
                                    <p style={{ fontSize: "medium" }}>
                                      <b>Upload Secondary File Here</b>{" "}
                                    </p>
                                  }
                                  showPreviewsInDropzone={false}
                                  showAlerts={false}
                                  onAdd={(files) =>
                                    this.handleDropzoneSecondary(files)
                                  }
                                  fileObjects={slip}
                                />
                              </div>
                              <br />
                              <Paper
                                className="custom-list-upload"
                                component="ul"
                              >
                                {pictureSecondary !== null && (
                                  <Chip
                                    icon={<AttachFileOutlined />}
                                    label={pictureSecondary.name}
                                    onDelete={() => this.deletePicture(true)}
                                    className="custom-chip-upload"
                                  />
                                )}
                              </Paper>
                            </>
                          ) : (
                            ""
                          )}
                          <br />
                          <br />
                          <span className={classes.breakdownText}>
                            <b>Note:</b> If your payment doesn&apos;t match the
                            &quot;Total Amount to be Paid&quot;, we will process
                            the actual payment made to Rampver.
                          </span>
                          <br />
                          <br />
                        </div>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2} />
                  </Grid>
                )}

                <br />
                {loading ? (
                  <LinearProgress color="primary" />
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2} />
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} />
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          className={classes.button}
                          disabled={investDisable}
                          onClick={(e) => this.submit(e)}
                          fullWidth={true}
                        >
                          Invest
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2} xl={2} />
                  </Grid>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const Payment = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase,
  withCookies
)(PaymentBase);

export default PaymentPage;
