import React, { Component } from "react";
import { AuthUserContext, withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import NavigationMenus from "../NavigationMenus";
import { Breadcrumbs, Typography, Link, Grid, Paper } from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import Helpers from "../Helpers";
import Functions from "./Functions";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  recommendedInvestCardTitle: {
    display: "flex",
    justifyContent: "left",
    padding: "10px",
    borderRadius: "5px 5px 0px 0px",
    background: "#256141 !important",
    color: "white !important",
    fontWeight: "600",
    fontSize: "18px",
    letterSpacing: "0.04em;",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
  },
}));

const InvestmentHistoryByTransactionIdPage = () => {
  const classes = useStyles();
  return (
    <>
      <NavigationMenus
        content={<InvestmentHistoryByTransactionId classes={classes} />}
      />
    </>
  );
};

class InvestmentHistoryByTransactionIdBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // investmentDetails: {
      //   transactionId: {
      //     text: "Transaction ID",
      //     value: "",
      //   },
      //   dateProcessed: {
      //     text: "Date Processed",
      //     value: "",
      //   },
      //   investmentName: {
      //     text: "Investment Name",
      //     value: "",
      //   },
      //   investmentCost: {
      //     text: "Amount of Investment",
      //     value: "",
      //   },
      //   rateAtBooking: {
      //     text: "NAVPS/Rate At Booking",
      //     value: "",
      //   },
      //   shares: {
      //     text: "Number of Shares",
      //     value: "",
      //   },
      // },
      transId: "",
      detailsToDisplay: {},
      type: "",
    };
    this.helpers = new Helpers();
    this.functions = new Functions();
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    let transactionId = this.props.match.params.id;
    const transactionsRef = this.props.firebase.db.collection("transactions");
    //let investmentDetails = this.state.investmentDetails;
    let detailsToDisplay = {};

    transactionsRef
      .where("eventId", "==", transactionId)
      .limit(1)
      .get()
      .then((snapshot) => {
        if (snapshot.size !== 1) throw new Error("Transaction Does Not Exist!");

        const data = snapshot.docs[0].data();
        console.log(data);

        const amount = data.transactionType === 'reinvestment' ? '-' : this.helpers.formatToPHP(data.amount, 2);

        // investmentDetails["transactionId"].value = data.eventId;
        // investmentDetails["investmentName"].value = data.fundDetails.fundName;
        const dateProcessed = moment.unix(data.dateProcessed.seconds);
        const formattedDate = dateProcessed.isValid()
          ? dateProcessed.format("MM/DD/YYYY")
          : "n/a";

        detailsToDisplay.dateProcessed = {
          text: "Date Processed",
          value: formattedDate,
        };

        detailsToDisplay.investmentName = {
          text: "Fund Name",
          value: data.fundDetails.fundName,
        };

        let dateString = moment
          .unix(data.datePlaced.seconds)
          .format("MM/DD/YYYY");

        let type = ["processing", "approved", "declined"].includes(data.status)
          ? "Investment"
          : "Redemption";

        if (type === "Investment") {
          detailsToDisplay.investmentCost = {
            text: "Amount of Investment",
            value: amount,
          };

          detailsToDisplay.rateAtBooking = {
            text: "NAVPS/Rate At Booking",
            value:
              data.rateAtBooking === undefined || data.rateAtBooking === ""
                ? "n/a"
                : this.helpers.formatToPHP(data.rateAtBooking, 4) +
                  `, Placed at ${dateString} `,
          };

          detailsToDisplay.shares = {
            text: "Number of Shares",
            value: this.helpers.numberCommaSeparated(data.shares),
          };
        } else if (type === "Redemption") {
          detailsToDisplay.shares = {
            text: "Shares Redeemed",
            value: this.helpers.numberCommaSeparated(data.shares),
          };

          detailsToDisplay.rateAtBooking = {
            text: "NAVPS/Rate",
            value:
              data.rateAtBooking === undefined || data.rateAtBooking === ""
                ? "n/a"
                : this.helpers.formatToPHP(data.rateAtBooking, 4) +
                  `, Placed at ${dateString} `,
          };

          detailsToDisplay.marketValue = {
            text: "Market Value",
            value:
              data.marketValue === undefined || data.marketValue === ""
                ? "n/a"
                : data.marketValue,
          };

          detailsToDisplay.serviceFee = {
            text: "Flat Service Fee",
            value:
              data.serviceFee === undefined || data.serviceFee === ""
                ? "n/a"
                : data.serviceFee,
          };

          detailsToDisplay.exitFee = {
            text: "Exit Fee",
            value:
              data.exitFee === undefined || data.exitFee === ""
                ? "n/a"
                : data.exitFee,
          };

          detailsToDisplay.netPay = {
            text: "Amount to be Collected",
            value:
              data.netPay === undefined || data.netPay === ""
                ? "n/a"
                : data.netPay,
          };
        }

        this.setState({
          detailsToDisplay,
          type,
          transId: transactionId,
        });
      })
      .catch((err) => {
        alert(err);
        console.log(err);
      });
  }

  goBackToTransactions = () => {
    var params = this.props.history.location.state;

    if (params !== null && params !== undefined) {
      this.props.history.push({
        pathname: ROUTES.INVESTMENT_HISTORY,
        state: { fundCode: params.fundCode, companyId: params.companyId },
      });
    } else {
      this.props.history.push(ROUTES.INVESTMENT_HISTORY);
    }
  };

  render() {
    document.body.style.backgroundColor = "white";
    const { detailsToDisplay, transId, type } = this.state;

    const { classes } = this.props;
    const detailsKeys = Object.keys(detailsToDisplay);

    const renderFundDetails = detailsKeys.map((col, index) => (
      <tr key={index}>
        <td>{detailsToDisplay[col].text}</td>
        <td>{detailsToDisplay[col].value}</td>
      </tr>
    ));

    let headline = `${type} Details`;

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textSecondary" href={ROUTES.DASHBOARD}>
            Dashboard
          </Link>
          <Link color="textSecondary" onClick={this.goBackToTransactions}>
            Transactions
          </Link>
          <Typography color="textPrimary" aria-current="page">
            {transId}
          </Typography>
        </Breadcrumbs>
        <div className="content-body">
          <div className="history-trans-id">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h1>{headline}</h1>
                <div className="fund-details">
                  <Paper elevation={2}>
                    <div className={classes.recommendedInvestCardTitle}>
                      <span>Transaction ID: {transId}</span>
                    </div>
                    <table className="tbl-border-bottom">
                      <tbody>{renderFundDetails}</tbody>
                    </table>
                  </Paper>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const InvestmentHistoryByTransactionId = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(InvestmentHistoryByTransactionIdBase);

export default InvestmentHistoryByTransactionIdPage;

export { InvestmentHistoryByTransactionId };
