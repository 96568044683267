import React, { Component } from "react";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import NavigationMenus from "../NavigationMenus";
import {
  Breadcrumbs,
  Typography,
  Link,
  Grid,
  Divider,
  Paper,
  Button,
  Box,
} from "@material-ui/core";
import { AuthUserContext } from "../Session";
import CustomDataTable from "../ConfirmNewPassword/CustomDataTable";
import * as ROUTES from "../../constants/route";
import Helpers from "../Helpers";
import Filters from "./Filters";
import moment from "moment";
import CustomButton from "../Button";
import { ModalOneButtonAgree } from "./ModalAlert";
import Functions from "../MyInvestments/Functions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ExportTransactionData } from "./ExportData";

const useStyles = makeStyles(() => ({
  loading: {
    margin: "auto",
    width: 50,
  },
}));

const InvestmentHistoryPage = () => {
  const classes = useStyles();
  const smBreakpoint = useMediaQuery(useTheme().breakpoints.up("sm"));
  return (
    <>
      <NavigationMenus
        content={
          <InvestmentHistory classes={classes} smBreakpoint={smBreakpoint} />
        }
      />
    </>
  );
};

class InvestmentHistoryBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleDatePicker: false,
      start_date: new Date(),
      end_date: new Date(),
      searchByFundName: "",
      investment_histories: [],
      columns: [
        {
          name: "trans_id",
          label: "Transaction ID",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "fund_name",
          label: "Fund Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "amount",
          label: "Amount Invested/Redeemed",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "transactionType",
          label: "Investment Type",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
              if (!value) return 'Single'
              else return value.charAt(0).toUpperCase() + value.slice(1);
            }
          },
        },
        {
          name: "status",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (value) => {
              if (value === "Processing") return "Pending";
              else if (value === "Processing Redemption")
                return "Pending Redemption";
              else return value;
            },
          },
        },
      ],
      fund: "all",
      options: {
        search: false,
        filter: false,
        responsive: "standard",
        selectableRowsHideCheckboxes: true,
        selectableRows: "none",
        download: false,
        onRowClick: (data) => {
          this.transactionClickRedirect(data[0]);
        },
      },
      optionsMobile: {
        download: false,
        customRowRender: (data) => {
          return (
            <div style={{ margin: "5px" }}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "600", fontSize: "12px" }}>
                      {data[3]}
                    </td>
                    <td
                      style={{
                        fontWeight: "600",
                        fontSize: "14px",
                        textAlign: "right",
                      }}
                    >
                      {data[4]}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "10px" }}>{data[1]}</td>
                    <td
                      style={{
                        fontSize: "12px",
                        color: "#12646E",
                        textAlign: "right",
                      }}
                    >
                      {data[5]}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ fontSize: "10px", color: "#12646E" }}
                      onClick={() => this.transactionClickRedirect(data[0])}
                    >
                      View Details
                    </td>
                  </tr>
                </tbody>
              </table>
              <Divider />
            </div>
          );
        },
      },
      investment_histories_with_status: [],
      loading: false,
      loading_search: false,
      hasParams: false,
      myCurrentFunds: [],
      sharesPerFund: [],
      currentShares: 0,
      currentRates: "",
      currentRatesDate: "",
      fundRate: "",
      sharesToRedeem: 0,
      redeemModalOpen: false,
      redeemData: null,
      modalAlertOpen: false,
      modalAlertMessage: "",
      modalAlertSeverity: "Warning",
      modalOpen: true,
      initialLoading: true,
    };
    this.helpers = new Helpers();

    this.functions = new Functions();
  }

  static contextType = AuthUserContext;

  timer = null;

  transactionClickRedirect(data) {
    var params = this.props.history.location.state;

    if (params !== null && params !== undefined) {
      this.props.history.push({
        pathname: `${ROUTES.INVESTMENT_HISTORY}/${data}`,
        state: { fundCode: params.fundCode, companyId: params.companyId },
      });
    } else {
      this.props.history.push(`${ROUTES.INVESTMENT_HISTORY}/${data}`);
    }
  }

  componentDidMount() {
    let helper = this.helpers;
    let transactionsRef = this.props.firebase.db
      .collection("transactions")
      .where("clientDetails.userUid", "==", this.context.uid)
      .orderBy("datePlaced", "asc");
    const investmentsRef = this.props.firebase.db.collection("investments");
    const fundRef = this.props.firebase.db.collection("funds");
    const fundRatesRef = this.props.firebase.db
      .collection("fundRates")
      .orderBy("dateUploaded", "desc")
      .limit(1);

    var investment_histories = [];
    var me = this;
    let earliestDate;
    let myFunds = [];
    myFunds.push({
      value: "all",
      name: "All Funds",
    });

    var params = this.props.history.location.state;
    let uniqueFunds = [];

    var fundRates;
    let latestRatesUid;
    var funds = [];

    fundRatesRef
      .get()
      .then((snapshot) => {
        latestRatesUid = snapshot.docs[0].id;
        fundRates = snapshot.docs[0].data();
        return fundRef.get();
      })
      .then((snapshot) => {
        snapshot.docs.forEach(function (doc) {
          fundRates.fundDetails.forEach(function (value) {
            if (value.fundName === doc.data().name) {
              var fund = doc.data();
              fund.latestRatesUid = latestRatesUid;
              fund.docId = doc.id;
              fund.fundRates = value;
              fund.fundRates.date = fundRates.date.toDate().toDateString();
              funds.push(fund);
            }
          });
        });
        this.setState({ funds: funds });
        return transactionsRef.get();
      })
      .then((snapshot) => {
        let userAcctNumber = "";
        snapshot.docs.forEach(function (doc, key) {
          if (key === 0) {
            earliestDate = doc.data().datePlaced.toDate();
          }
          let amount;
          if ("processingRedemption" === doc.data().status) {
            let rate;
            fundRates.fundDetails.forEach((index) => {
              if (index.fundName === doc.data().fundDetails.fundName)
                rate = index.rate;
            });
            amount = helper.formatToPHP(doc.data().shares * rate, 2);
            amount = `-${amount}(*)`;
          } else if ("redeemed" === doc.data().status) {
            amount = helper.formatToPHP(doc.data().amount, 2);
            amount = `-${amount}`;
          } else {

            amount = helper.formatToPHP(doc.data().amount, 2);
          }
          console.log(doc.data());

          if (doc.data().transactionType === 'reinvestment') {
            console.log('inss');
            amount = '-';
          }

          let time = doc.data().datePlaced.toDate().toTimeString();
          time = time.split(" ")[0];

          let dateP = "-";
          if (doc.data().dateProcessed !== "")
            dateP = doc.data().dateProcessed.seconds;

          let dateT = "-";
          dateT = new Date(doc.data().datePlaced.toDate())
            .toLocaleString()
            .split(/\D/)
            .slice(0, 3)
            .map((num) => num.padStart(2, "0"))
            .join("/");

          investment_histories.push({
            trans_id: doc.data().eventId,
            trans_date: dateT + ` ${time}`,
            process_date: dateP,
            fund_name: doc.data().fundDetails.fundName,
            transactionType: doc.data().transactionType === 'reinvestment' ? 'Dividend Reinvestment' : doc.data().transactionType,
            maturity_date: doc.data().dateMaturity,
            status: (
              doc.data().status.charAt(0).toUpperCase() +
              doc.data().status.slice(1)
            ).replace(/([a-z](?=[A-Z]))/g, "$1 "),
            amount: amount,
          });

          if (
            !uniqueFunds.some(
              (fund) => fund.code === doc.data().fundDetails.fundCode
            )
          ) {
            myFunds.push({
              value: doc.data().fundDetails.fundName,
              name: doc.data().fundDetails.fundName,
            });

            uniqueFunds.push({
              name: doc.data().fundDetails.fundName,
              code: doc.data().fundDetails.fundCode,
              companyId: doc.data().fundCompanyDetails.fundCompanyId,
              fundId: doc.data().fundDetails.fundId,
              shares: 0,
            });
          }
          userAcctNumber = doc.data().clientDetails.userAccountNumber;
        });
        return investmentsRef.where("useruid", "==", userAcctNumber).get();
      })
      .then((snapshot) => {
        snapshot.docs.forEach((invDoc) => {
          uniqueFunds.every((index) => {
            if (invDoc.data().fundCode === index.code) {
              index.shares = invDoc.data().value;
              index.investmentId = invDoc.id;
              return false;
            }
            return true;
          });
        });
        this.setState({
          investment_histories: investment_histories,
          investment_histories_with_status: investment_histories,
          start_date: earliestDate,
          myCurrentFunds: myFunds,
          sharesPerFund: uniqueFunds,
          currentRates: fundRates.fundDetails,
          currentRatesDate: fundRates.date,
        });
        me.searchFilter();
        if (params !== null && params !== undefined)
          this.handleChange(params.fundName, "fund");

        return this.setState({
          initialLoading: false,
        });
      })
      .catch((error) => {
        alert(error);
      });
  }

  handleChange = (value, name) => {
    this.setState(
      {
        [name]: value,
        loading: true,
      },
      () => {
        this.searchFilter()
          .then((result) => {
            let shares = "";
            let curRate = "";
            if (name === "fund") {
              this.state.sharesPerFund.forEach((index) => {
                if (value === index.name) shares = index.shares;
              });

              this.state.currentRates.forEach((index) => {
                if (value === index.fundName) curRate = index.rate;
              });
            }
            this.setState({
              investment_histories_with_status: result,
              loading: false,
              currentShares: shares,
              fundRate: curRate,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };

  handleKeyDown = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.search(), 1000);
  };

  handleSharesChange = (value) => {
    this.setState({ sharesToRedeem: value });
  };

  handleRedeemDisagree = () => {
    this.setState({ redeemModalOpen: false }, () => {
      this.handleSharesChange("");
    });
  };

  handleRedeemModal = (data) => {
    this.setState({ redeemModalOpen: true, redeemData: data });
  };

  handleRedeemButton = () => {
    const { sharesPerFund, funds, fund } = this.state;
    let selected = sharesPerFund.find((item) => item.name === fund);
    let latestRate;
    funds.forEach((val) => {
      if (val.code === selected.code) latestRate = val.latestRatesUid;
    });

    let curDocId = "";
    this.state.sharesPerFund.forEach((index) => {
      if (index.name === fund) curDocId = index.fundId;
    });
    let date = new Date(this.state.currentRatesDate.seconds * 1000);
    this.props.history.push({
      pathname: ROUTES.REDEMPTION_PAGE,
      state: {
        currentFund: this.state.fund,
        currentRate: this.state.fundRate,
        currentRatesDate: `${date.getMonth() + 1
          }/${date.getDate()}/${date.getFullYear()}`,
        maxShares: this.state.currentShares,
        shares: this.state.sharesToRedeem,
        fundId: curDocId,
        postdata: {
          fundCode: selected.code,
          companyId: selected.companyId,
          userUid: this.context.uid,
          investmentUid: selected.investmentId,
          rateUid: latestRate,
        },
      },
    });
  };

  handleRedeemAgree = () => {
    const { sharesPerFund, sharesToRedeem, funds, fund } = this.state;
    this.setState({ redeemModalOpen: false }, () => {
      let latestRate;
      let selected = sharesPerFund.find((item) => item.name === fund);
      funds.forEach((val) => {
        if (val.code === selected.code) latestRate = val.latestRatesUid;
      });
      let postData = {
        fundCode: selected.code,
        shares: sharesToRedeem,
        companyId: selected.companyId,
        userUid: this.context.uid,
        investmentUid: selected.investmentId,
        rateUid: latestRate,
      };
      this.functions
        .doRedeemInvestment(postData)
        .then((res) => {
          this.setState(
            {
              modalAlertOpen: true,
              modalAlertMessage: res.data.message,
              modalAlertSeverity: res.data.ok ? "Success" : "Warning",
            },
            () => {
              this.handleSharesChange("");
            }
          );
        })
        .catch((error) => {
          this.handleSharesChange("");
          console.log(error);
        });
    });
  };

  handleSharesChange = (value) => {
    this.setState({ sharesToRedeem: value });
  };
  search = () => {
    this.setState({ loading_search: true });
    this.filterBySearch()
      .then((result) => {
        this.setState({
          investment_histories_with_status: result,
          loading_search: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterBySearch = () => {
    const { searchByFundName, investment_histories } = this.state;

    if (searchByFundName === "") {
      return Promise.resolve(investment_histories);
    }

    const filtered_result = investment_histories.filter(value =>
      Object.values(value).some(
        val => typeof val === 'string' && val.toLowerCase().includes(searchByFundName.toLowerCase())
      )
    );

    return Promise.resolve(filtered_result);
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.searchFilter()
      .then((result) => {
        this.setState({
          investment_histories_with_status: result,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  searchFilter = () => {
    var me = this;
    return new Promise(function (resolve, reject) {
      const { start_date, end_date, fund, investment_histories } = me.state;

      var start = moment(start_date).format("YYYY-MM-DD");
      var end = moment(end_date).format("YYYY-MM-DD");
      var filtered_result = [];
      try {
        investment_histories
          .filter(function (value) {
            var transDate = moment(value.trans_date).format("YYYY-MM-DD");
            if (fund !== "all") {
              return (
                new Date(start) <= new Date(transDate) &&
                new Date(transDate) <= new Date(end) &&
                value.fund_name === fund
              );
            } else {
              return (
                new Date(start) <= new Date(transDate) &&
                new Date(transDate) <= new Date(end)
              );
            }
          })
          .map(function (filtered) {
            return filtered_result.push(filtered);
          });
        resolve(filtered_result);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  handleModalAlertCLose = () => {
    this.setState({ modalAlertOpen: false });
    window.location.reload();
  };

  handleInvestButton = (e, fund) => {
    e.preventDefault();
    let curDocId = "";
    this.state.sharesPerFund.forEach((index) => {
      if (index.name === fund) curDocId = index.fundId;
    });
    this.props.history.push({
      pathname: ROUTES.INVESTMENT_PAYMENT + `/${curDocId}`,
    });
  };

  render() {
    document.body.style.backgroundColor = "white";
    const { classes, smBreakpoint } = this.props;
    const {
      investment_histories,
      investment_histories_with_status,
      columns,
      options,
      myCurrentFunds,
      fund,
      currentShares,
      currentRatesDate,
      fundRate,
      modalAlertOpen,
      modalAlertMessage,
      initialLoading,
      optionsMobile,
    } = this.state;

    let disableButton = fund === "all";
    let totalMarketValue =
      fund === "all"
        ? "N/A"
        : this.helpers.formatToPHP(
          parseFloat(fundRate) * parseFloat(currentShares),
          2
        );
    let gridSize = fund === "all" ? 12 : 9;
    let curShares =
      typeof currentShares !== "string"
        ? parseFloat(currentShares.toFixed(2)).toLocaleString()
        : 0;
    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textSecondary" href={ROUTES.DASHBOARD}>
            Dashboard
          </Link>
          <Typography color="textPrimary" aria-current="page">
            Investment History
          </Typography>
        </Breadcrumbs>

        <ModalOneButtonAgree
          function={this.handleModalAlertCLose}
          open={modalAlertOpen}
          message={modalAlertMessage}
          executeFuncOnAgree={this.handleModalAlertCLose}
        />
        {initialLoading ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <h1>Investment History</h1>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <h2 style={{ marginRight: "20px" }}>
                  {fund === "all" ? "All Funds" : fund}
                </h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                <Filters
                  handleChange={this.handleChange}
                  state={this.state}
                  funds={myCurrentFunds}
                  fund={fund}
                  submitSearchFilter={this.submit}
                  search={this.search}
                  handleKeyDown={this.handleKeyDown}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              {fund === "all" ? (
                ""
              ) : (
                <Grid item xs={12} sm={12} lg={3} xl={3}>
                  <Paper elevation={3} style={{ padding: "10px" }}>
                    <h3> Today is {this.helpers.dateFormat(new Date())}</h3>
                    <br />
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>Shares:</td>
                        <td style={{ textAlign: "right" }}>{curShares}</td>
                      </tr>
                      <tr>
                        <td>{`NAVPS as of ${this.helpers.dateFormat(
                          currentRatesDate.toDate()
                        )}`}</td>
                        <td style={{ textAlign: "right" }}>
                          {this.helpers.formatToPHP(fundRate, 4)}
                        </td>
                      </tr>
                    </table>
                    <br />
                    <Divider />
                    <br />
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>Total Market Value:</td>
                        <td style={{ textAlign: "right" }}>
                          {totalMarketValue}
                        </td>
                      </tr>
                    </table>
                    <br />
                    <br />
                    <CustomButton
                      text="Add to Investment"
                      disabled={disableButton}
                      buttonTheme="primary"
                      onClick={(e) => {
                        this.handleInvestButton(e, fund);
                      }}
                      fullWidth={true}
                    />
                    <div style={{ padding: "4px" }}></div>
                    <CustomButton
                      text="Redeem"
                      variant="outlined"
                      color="secondary"
                      disabled={disableButton || curShares <= 0}
                      buttonTheme="custom"
                      onClick={(e) => {
                        this.handleRedeemButton(e);
                      }}
                      fullWidth={true}
                    />
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12} sm={12} lg={gridSize} xl={gridSize}>
                {smBreakpoint ? (
                  <CustomDataTable
                    title={"Investment History"}
                    data={investment_histories_with_status}
                    columns={columns}
                    options={options}
                  />
                ) : (
                  <CustomDataTable
                    title={"Investment History"}
                    data={investment_histories_with_status}
                    columns={columns}
                    options={optionsMobile}
                  />
                )}
                <br />
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} lg={6} xl={6}>
                    <b>
                      * This may not reflect the final amount to be redeemed.
                    </b>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} xl={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <ExportTransactionData
                        data={investment_histories}
                        button={
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{
                              width: "200px",
                              height: "40px",
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            Export All Transactions
                          </Button>
                        }
                        filename={`My transactions - ${moment().format(
                          "MM/DD/YY - hh:mm a"
                        )}`}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const InvestmentHistory = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(InvestmentHistoryBase);

export default InvestmentHistoryPage;

export { InvestmentHistory };
