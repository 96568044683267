import React, { Component } from "react";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { compose } from "recompose";
import NavigationMenus from "../NavigationMenus";
import {
  Grid,
  Paper,
  Fade,
  FormControlLabel,
  FormControl,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
import EcoIcon from "@material-ui/icons/Eco";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/route";
import { Pie } from "react-chartjs-2";
import { AuthUserContext } from "../Session";
import Helpers from "../Helpers";
import Functions from "../MyInvestments/Functions";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import { orange } from "@material-ui/core/colors";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { PorfolioGraph } from "../MyInvestments/PerformanceGraph";
import moment from "moment";

import DashboardFunctions from "./functions";
import RecurringInvestmentDialog from "./modal";
import DashboardLearningCenter from "./dashboardLearningCenter";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#c8932a",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#c8932a",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
    height: 65,
    width: 10,
  },
})(StepConnector);

const FlatConnector = withStyles({
  active: {
    "& $line": {
      borderColor: "#c8932a",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#c8932a",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[900]),
    backgroundColor: "#C8932A",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    padding: "10px 45px",
    "&:hover": {
      backgroundColor: "#b8892e",
    },
    "&:disabled": {
      backgroundColor: "#B8B8B8",
      color: "black",
    },
  },
  investmentButton: {
    color: theme.palette.getContrastText(orange[900]),
    backgroundColor: "#C8932A",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "16px",
    padding: "10px 110px",
    "&:hover": {
      backgroundColor: "#b8892e",
    },
    "&:disabled": {
      backgroundColor: "#B8B8B8",
      color: "black",
    },
  },
  declined: {
    "&:disabled": {
      backgroundColor: "#753939",
      color: "white",
    },
  },
  less_padding: {
    padding: "10px 15px",
  },
  thinButton: {
    padding: "5px 45px",
  },
  flexDisplay: {
    display: "flex",
  },
  suggestedFunds: {
    color: "white",
    fontWeight: "600",
    fontSize: "18px",
    letterSpacing: "0.04em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  investmentBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 5px",
    padding: "5px 0px",
  },
  investmentBoxB: {
    fontWeight: "600",
    fontSize: "18px",
    letterSpacing: "0.03em",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  investmentBoxS: {
    fontWeight: "100",
    fontStyle: "italic",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  recommendedInvestCardTitle: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "5px 5px 0px 0px",
    background: "#256141 !important",
    color: "white !important",
    fontWeight: "600",
    fontSize: "12px",
    letterSpacing: "0.015em;",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
  },
  recommendedInvestCardButton: {
    display: "flex",
    justifyContent: "center",
    margin: "5px 10px",
  },
  recommendedInvestCardText: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
  investAgain: {
    backgroundColor: "#C8932A",
    color: "white",
    fontFamily: "Gill Sans, Poppins",
    fontSize: "13px",
    padding: "10px 45px",
    "&:hover": {
      backgroundColor: "#b8892e",
    },
  },
}));

const DashboardPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));
  const lgBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <>
      <NavigationMenus
        content={
          <Dashboard
            classes={classes}
            theme={theme}
            smBreakpoint={smBreakpoint}
            lgBreakpoint={lgBreakpoint}
          />
        }
      />
    </>
  );
};

class DashboardBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totals: [
        {
          id: 1,
          icon: <EcoIcon />,
          title: "Total Amount Invested",
          number: "0.00",
          class: "invested",
        },
        {
          id: 2,
          icon: <EqualizerIcon />,
          title: "Total Market Value",
          number: "0.00",
          class: "depreciation",
        },
        {
          id: 3,
          icon: <WhatshotIcon />,
          title: "Risk Profile",
          number: "N/A",
          class: "risk",
        },
      ],
      investments: [],
      backgroundColor: [
        "rgb(0,84,36)",
        "rgb(12,157,109)",
        "rgb(84,229,181)",
        "rgb(157,255,254)",
        "rgb(0,84,36)",
        "rgb(12,157,109)",
        "rgb(84,229,181)",
      ],
      pie_chart_data: {},
      pie_chart_options: {
        responsive: true,
        legend: {
          position: "bottom",
          align: "start",
          labels: {
            boxWidth: 20,
            padding: 20,
            usePointStyle: true,
          },
        },
      },
      suggested_funds: [],
      user: null,
      verifyRedirect: ROUTES.LONG_KYC,
      kyc: null,
      show_all_inv: false,
      funds: [],
      navps: null,
      investments_show: [],
      totalMarketValue: 0,
      myInvestments: [],
      redeemModalOpen: false,
      redeemData: null,
      sharesToRedeem: "",
      pieRedraw: false,
      modalAlertOpen: false,
      modalAlertMessage: "",
      modalAlertSeverity: "Warning",
      modalOpen: true,
      activeStep: 0,
      dashboardStep: -1,
      activeLoading: true,
      totalGain: 0,
      perfGraphData: [],
      hasSummary: false,
      isDoneLoadingGraph: false,
      loadingGraph: false,
      modalRecurringOpen: false,
      renderFundName: "",
      renderInvested: "",
      renderMinInvested: "",
      tier: null,
      transactionsTotal: 0,
      currentIndex: 0,
    };
    this.helpers = new Helpers();
    this.pieRef = React.createRef();
    this.functions = new Functions();
    this.DashboardFunctions = new DashboardFunctions();
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    const bgcolor = "#fff";
    const bodyElt = document.querySelector("body");
    bodyElt.style.backgroundColor = bgcolor;
    var uid = this.context.uid;
    const userRef = this.props.firebase.db.collection("users");
    userRef
      .doc(uid)
      .collection("KYC")
      .doc("KYC")
      .get()
      .then((result) => {
        if (result.data() !== undefined) {
          this.setState({ kyc: result.data() });
        }
        return userRef.doc(uid).get();
      })
      .then((snapshot) => {
        const hasKyc = this.state.kyc !== null;

        let t = hasKyc ? snapshot.data().stats?.tier ?? -1 : 1;
        if (hasKyc && this.state.kyc.validated === "pending" && t < 0) {
          t = 1;
        }

        this.setState({
          user: snapshot.data(),
          tier: t,
        });
        sessionStorage.setItem("tier", t);
        sessionStorage.setItem(
          "approvedLongKYC",
          snapshot.data().approvedLongKYC
        );
        sessionStorage.setItem(
          "validated",
          this.state.kyc === null
            ? ""
            : this.state.kyc.validated === undefined
              ? ""
              : this.state.kyc.validated
        );

        if (snapshot.data().riskProfile !== undefined) {
          var totals = [...this.state.totals];
          totals[2].number = snapshot.data().riskProfile;
          this.setState({ totals: totals });
        }

        let arrPromise = [];

        arrPromise.push(this.updatePortfolio());
        arrPromise.push(this.getFunds());
        arrPromise.push(this.getCurrentInvestmentTotal());
        arrPromise.push(this.setActiveStep(snapshot.data()));
        arrPromise.push(this.getInvestments(false));
        return Promise.all(arrPromise);
      })
      .then(() => {
        let pieData = this.state.pie_chart_data;
        let total = 0;

        pieData.labels.forEach((item, index) => {
          total += pieData.datasets[0].data[index];
        });
        pieData.labels.forEach((item, index) => {
          let percentage = (pieData.datasets[0].data[index] / total) * 100;
          percentage = parseFloat(percentage.toFixed(2));
          pieData.labels[index] = `${percentage}% - ${pieData.labels[index]}`;
          let rate = parseFloat(pieData.datasets[0].data[index].toFixed(2));
          pieData.datasets[0].data[index] = rate;
        });

        this.setState(
          {
            pie_chart_data: pieData,
            pieRedraw: true,
          },
          () => {
            this.setState({ pieRedraw: false });
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.smBreakpoint !== this.props.smBreakpoint) {
      this.getInvestmentsShow(true);
    }
  }

  setActiveStep = (user) => {
    let curStep = 0;
    let kyc = this.state.kyc;
    if (kyc === undefined || kyc === null) curStep = 0;
    else {
      if ("validated" in kyc) {
        if (
          this.state.tier === 1 &&
          (kyc.validated === "pending" || kyc.validated === "declined")
        ) {
          curStep = 0;
        }
        if (
          this.state.tier === 2 &&
          (kyc.validated === "pending" || kyc.validated === "declined")
        ) {
          curStep = 1;
        } else if (kyc.validated === "verified") {
          curStep = 1;

          if (!user.firstTimeInvestor) curStep = 2;
        }
      }
    }
    return this.setState({ activeStep: curStep, activeLoading: false });
  };

  updatePortfolio = async () => {
    try {
      this.setState({ isDoneLoadingGraph: false })
      const chartData = await this.DashboardFunctions.getInvestmentGraphData(this.context.uid);
      const perfGraphData = chartData.map((x) => [x.date, x.rate]);

      this.setState({ perfGraphData, isDoneLoadingGraph: true })
    } catch (err) {
      console.log(err);
    }
  };

  getFunds = () => {
    const fundRef = this.props.firebase.db.collection("funds");
    const fundRatesRef = this.props.firebase.db.collection("fundRates");
    var fundRates;
    let latestRatesUid;
    var funds = [];
    return fundRatesRef
      .orderBy("date", "desc")
      .limit(1)
      .get()
      .then((snapshot) => {
        let entryArr = snapshot.docs;
        let dateGroup = [];

        let latestDateEntry = entryArr.pop();
        for (let i = 0; i < snapshot.docs.length; i++) {
          if (
            latestDateEntry.data().date.seconds ===
            snapshot.docs[i].data().date.seconds
          )
            dateGroup.push(snapshot.docs[i]);
        }
        dateGroup.sort(function (a, b) {
          return a.data().dateUploaded.seconds - b.data().dateUploaded.seconds;
        });
        fundRates = dateGroup.pop();
        latestRatesUid = fundRates.id;
        fundRates = fundRates.data();
        return fundRef.get();
      })
      .then((snapshot) => {
        snapshot.docs.forEach(function (doc) {
          fundRates.fundDetails.forEach(function (value) {
            if (value.fundName === doc.data().name) {
              var fund = doc.data();
              fund.latestRatesUid = latestRatesUid;
              fund.docId = doc.id;
              fund.fundRates = value;
              fund.fundRates.date = value.date;
              fund.color = doc.data().color;
              funds.push(fund);
            }
          });
        });
        this.getSuggestedFunds();
        return this.setState({ funds: funds, navps: fundRates });
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  redirectFunds = (id) => {
    this.props.history.push(ROUTES.FUNDS + "/" + id);
  };

  redirectFundPayment = (id) => {
    this.props.history.push(ROUTES.FUNDS + "/" + id + "/payment");
  };

  getSuggestedFunds = () => {
    let helper = this.helpers;
    var funds = this.state.funds;
    var suggested_funds = [];
    var count = 0;
    var user = this.state.user;
    if (
      ("hasShortKYC" in user && !("hasLongKYC" in user)) ||
      (!("hasShortKYC" in user) && !("hasLongKYC" in user))
    ) {
      funds.forEach(function (value) {
        if (count < 3) {
          var for_agg = "";
          if (value.riskType === "aggressive")
            for_agg = "For Aggressive-type investors.";
          else if (value.riskType === "moderate")
            for_agg = "For Moderate-type investors.";
          else for_agg = "For Conservative-type investors.";
          suggested_funds.push({
            id: value.name,
            docId: value.docId,
            title: value.name,
            for_agg: for_agg,
            min:
              "Min. initial investment is " +
              helper.formatToPHP(parseFloat(value.minInitialInvestment), 2),
            min_add:
              "Min. additional investment is " +
              helper.formatToPHP(parseFloat(value.minAdditionalInvestment), 2),
            price_per_share: helper.formatToPHP(
              parseFloat(value.fundRates.rate),
              2
            ),
            date: new Date(value.fundRates.date.seconds * 1000)
              .toISOString()
              .split("T")[0],
            year_to_date:
              parseFloat(value.fundRates.ytd * 100).toFixed(2) + "%",
            riskType: value.riskType,
          });
          count++;
        }
      });
    } else if (
      "hasLongKYC" in user ||
      ("hasLongKYC" in user && "hasShortKYC" in user)
    ) {
      var riskType = this.state.totals[2].number;
      funds.forEach(function (value) {
        if (riskType === value.riskType) {
          var for_agg = "";
          if (value.riskType === "aggressive")
            for_agg = "For aggressive-type investor";
          else if (value.riskType === "moderate")
            for_agg = "For moderate-type investor";
          else for_agg = "For conservative-type investor";
          suggested_funds.push({
            id: value.name,
            docId: value.docId,
            title: value.name,
            for_agg: for_agg,
            min:
              "Min. initial investment is " +
              helper.formatToPHP(parseFloat(value.minInitialInvestment), 2),
            min_add:
              "Min. additional investment is " +
              helper.formatToPHP(parseFloat(value.minAdditionalInvestment), 2),
            price_per_share: helper.formatToPHP(
              parseFloat(value.fundRates.rate),
              4
            ),
            date: new Date(value.fundRates.date.seconds * 1000),
            year_to_date:
              parseFloat(value.fundRates.ytd * 100).toFixed(2) + "%",
            riskType: value.riskType,
          });
        }
      });
    }
    this.setState({ suggested_funds: suggested_funds });
  };

  randomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  getCurrentInvestmentTotal = async () => {
    const uid = this.context.uid;
    const transactionsRef = this.props.firebase.db.collection("transactions");

    try {
      const transactionsRes = await transactionsRef
        .where("clientDetails.userUid", "==", uid)
        .get();

      let runningTotal = 0;

      transactionsRes.forEach((doc) => {
        const data = doc.data();
        if (data.transactionType === 'dividendReinvestment') return;

        if (isNaN(data.amount)) return;

        switch (data.status) {
          case "processing":
          case "approved":
            runningTotal += data.amount;
            break;

          case "pendingRedemption":
          case "redeemed":
            runningTotal -= data.amount;
            break;

          default:
            break;
        }
      });
      this.setState({
        transactionsTotal: runningTotal,
      });
      sessionStorage.setItem("total", runningTotal);

    } catch (err) {
      console.log(err);
    }
  };

  getInvestments = (topfive = true) => {
    var uid = this.context.uid;
    const investmentsRef = this.props.firebase.db.collection("investments");
    const transactionsRef = this.props.firebase.db.collection("transactions");

    let user = this.state.user;
    var myInvestments = [];
    var total_invested = 0;
    var total_marketValue = 0;
    var pie_chart_data = {
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
      labels: [],
    };
    return investmentsRef
      .where("useruid", "==", user.accountNumber)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length === 0) this.setState({ dashboardStep: 0 });
        else {
          this.setState({ dashboardStep: 1 });
        }
        let arrPromise = [];

        snapshot.docs.forEach((invDoc) => {
          arrPromise.push(
            transactionsRef
              .where("clientDetails.userUid", "==", uid)
              .where("fundDetails.fundCode", "==", invDoc.data().fundCode)
              .where(
                "fundCompanyDetails.fundCompanyId",
                "==",
                invDoc.data().companyId
              )
              .orderBy("datePlaced", "desc")
              .get()
              .then(async (snapshot) => {
                let fund = this.state.funds.find(
                  (fund) => fund.code === invDoc.data().fundCode
                );
                var data = {
                  shares: invDoc.data().value,
                  invested: 0,
                  redemption: 0,
                  marketValue: this.helpers.formatToPHP(
                    fund.fundRates.rate * invDoc.data().value,
                    2
                  ),
                  fundCode: invDoc.data().fundCode,
                  companyId: invDoc.data().companyId,
                  investmentUid: invDoc.id,
                  currentRate: this.helpers.formatToPHP(fund.fundRates.rate, 4),
                  date: this.helpers.dateFormat(
                    new Date(fund.fundRates.date.seconds * 1000)
                  ),
                  gain: 0,
                };
                total_marketValue =
                  total_marketValue + fund.fundRates.rate * invDoc.data().value;

                if (invDoc.data().value > 0) {
                  await snapshot.docs.forEach((transDoc) => {
                    let exists = pie_chart_data.labels.includes(
                      transDoc.data().fundDetails.fundName
                    );
                    if (!exists) {
                      let curFund = this.state.funds.find(
                        ({ name }) =>
                          name === transDoc.data().fundDetails.fundName
                      );
                      switch (transDoc.data().status) {
                        case "processingRedemption":
                        case "redeemed":
                          pie_chart_data.datasets[0].data.push(
                            -Math.abs(
                              transDoc.data().shares * fund.fundRates.rate
                            )
                          );
                          break;

                        case "approved":
                          pie_chart_data.datasets[0].data.push(
                            transDoc.data().shares * fund.fundRates.rate
                          );
                          break;

                        default:
                          pie_chart_data.datasets[0].data.push(0);
                          break;
                      }
                      pie_chart_data.datasets[0].backgroundColor.push(
                        curFund.color
                      );
                      pie_chart_data.labels.push(
                        transDoc.data().fundDetails.fundName
                      );
                    } else {
                      let pie_chart_count = pie_chart_data.labels.indexOf(
                        transDoc.data().fundDetails.fundName
                      );
                      let existingValue =
                        pie_chart_data.datasets[0].data[pie_chart_count];

                      if (transDoc.data().status === "approved") {
                        existingValue =
                          existingValue +
                          transDoc.data().shares * fund.fundRates.rate;
                      }

                      if (
                        transDoc.data().status === "redeemed" ||
                        transDoc.data().status === "processingRedemption"
                      ) {
                        existingValue =
                          existingValue -
                          transDoc.data().shares * fund.fundRates.rate;
                      }

                      pie_chart_data.datasets[0].data[pie_chart_count] =
                        existingValue;
                    }
                    if (transDoc.data().status === "approved") {
                      total_invested = total_invested + transDoc.data().amount;
                      data.fundName = transDoc.data().fundDetails.fundName;
                      data.fundCompanyName =
                        transDoc.data().fundCompanyDetails.fundCompanyName;

                      let amount = transDoc.data().amount;
                      if (isNaN(amount)) {
                        amount = 0;
                      }
                      data.invested = data.invested + amount;

                    } else if (
                      transDoc.data().status === "redeemed" ||
                      transDoc.data().status === "processingRedemption"
                    ) {
                      let amount = transDoc.data().amount;
                      if (isNaN(amount)) {
                        amount = 0;
                      }
                      data.redemption =
                        data.redemption + transDoc.data().amount;
                    }
                  });
                }
                let gain = (fund.fundRates.rate * invDoc.data().value) / (data.invested - data.redemption) - 1;
                data.gain = gain;

                myInvestments.push(data);
              })
              .then(() => {
                let newFilledInvestments = [];
                myInvestments.forEach((item) => {
                  if (item.shares > 0) newFilledInvestments.push(item);
                });
                myInvestments = newFilledInvestments;

                var totals = [...this.state.totals];
                totals[0].number = this.helpers.formatToPHP(total_invested, 2);
                totals[1].number = total_marketValue;
                this.setState(
                  {
                    myInvestments: topfive
                      ? myInvestments.slice(0, 5)
                      : myInvestments,
                    totals: totals,
                    show_all_inv: !this.state.show_all_inv,
                    pie_chart_data: pie_chart_data,
                    pieRedraw: true,
                  },
                  () => {
                    this.getTotalMarketValue();
                    this.getInvestmentsShow(true);
                    //this.setState({ pieRedraw: false });
                  }
                );

                return Promise.resolve("done");
              })
          );
        });

        return Promise.all(arrPromise);
      })
      .catch((error) => {
        throw new Error(error);
      });
  };

  currencyFormat = (num) => {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  getInvestmentsShow = (topfive = false) => {
    var investments_show;
    if (!topfive) investments_show = this.state.myInvestments;
    else
      investments_show = this.props.smBreakpoint
        ? this.state.myInvestments.slice(0, 5)
        : this.state.myInvestments.slice(0, 3);
    this.setState({
      investments_show: investments_show,
      show_all_inv: !this.state.show_all_inv,
    });
  };

  getTotalMarketValue = () => {
    let totalInvestment = 0;
    let totalRedemption = 0;
    let totalMarketVal = this.state.totals[1].number;

    this.state.myInvestments.forEach((item) => {
      totalRedemption += item.redemption;
      totalInvestment += item.invested;
    });

    let totalGain = totalMarketVal / (totalInvestment - totalRedemption) - 1;
    if (isNaN(totalGain)) totalGain = 0;
    this.setState({ totalGain: totalGain });
  };

  handleRedeemDisagree = () => {
    this.setState({ redeemModalOpen: false }, () => {
      this.handleSharesChange("");
    });
  };

  handleRedeemModal = (data) => {
    this.setState({ redeemModalOpen: true, redeemData: data });
  };

  handleClickableInvestment = (e, type) => {
    e.preventDefault();
    e.stopPropagation();
    let fundArray = this.state.funds;
    let clickedFundData;
    for (let val in fundArray) {
      if (type.data.fundCode === fundArray[val].code) {
        clickedFundData = fundArray[val];
        break;
      }
    }
    switch (type.type) {
      case 1:
        this.props.history.push({
          pathname: ROUTES.INVESTMENT_HISTORY,
          state: {
            fundCode: type.data.fundCode,
            companyId: type.data.companyId,
            fundName: clickedFundData.name,
          },
        });
        break;

      case 2:
        this.setState({
          modalRecurringOpen: true,
          renderFundName: type.data.fundName,
          renderInvested: this.props.firebase.db.collection("transactions"),
          renderMinInvested: clickedFundData.minInitialInvestment,
        });

        break;

      default:
        alert("Invalid Type/Data");
        break;
    }
  };

  handleSharesChange = (value) => {
    this.setState({ sharesToRedeem: value });
  };

  handleRedeemAgree = () => {
    const { redeemData, sharesToRedeem, funds } = this.state;
    this.setState({ redeemModalOpen: false }, () => {
      let latestRate;
      funds.forEach((val) => {
        if (val.code === redeemData.fundCode) latestRate = val.latestRatesUid;
      });
      let postData = {
        fundCode: redeemData.fundCode,
        shares: sharesToRedeem,
        companyId: redeemData.companyId,
        userUid: this.context.uid,
        investmentUid: redeemData.investmentUid,
        rateUid: latestRate,
      };
      this.functions
        .doRedeemInvestment(postData)
        .then((res) => {
          this.setState(
            {
              modalAlertOpen: true,
              modalAlertMessage: res.data.message,
              modalAlertSeverity: res.data.ok ? "Success" : "Warning",
            },
            () => {
              this.getInvestments(false);
              this.handleSharesChange("");
            }
          );
        })
        .catch((error) => {
          this.handleSharesChange("");
          console.log(error);
        });
    });
  };

  handleModalAlertCLose = () => {
    this.setState({ modalAlertOpen: false });
  };

  getSteps = () => {
    return ["STEP 1", "STEP 2", "STEP 3"];
  };

  handleProceed = () => {
    this.props.history.push(ROUTES.LONG_KYC);
  };

  handleInvest = (all) => {
    if (all)
      this.props.history.push({
        pathname: ROUTES.MY_INVESTMENTS,
        state: { tab: 1 },
      });
    else this.props.history.push(ROUTES.MY_INVESTMENTS);
  };

  startInvest = (e) => {
    e.preventDefault();
    this.props.history.push(ROUTES.MY_INVESTMENTS);
  };

  formatReturns = (value) => {
    const formattedValue = value * 100;
    if (Math.abs(formattedValue) < 0.01) return (0).toFixed(2);
    return formattedValue.toFixed(2);
  };


  getInvestmentPerfDisplay = () => {
    return (
      <Paper elevation={3} className="content-margin">
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <PorfolioGraph
              title="Current Performance"
              data={this.state.perfGraphData}
              type="stockChart"
              doneLoading={this.state.isDoneLoadingGraph}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Pie
              redraw={this.state.pieRedraw}
              data={this.state.pie_chart_data}
              options={this.state.pie_chart_options}
              height={140}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  render() {
    const { classes, smBreakpoint, lgBreakpoint } = this.props;

    const {
      totals,
      suggested_funds,
      kyc,
      investments_show,
      myInvestments,
      modalOpen,
      activeStep,
      user,
      dashboardStep,
      activeLoading,
      totalGain,
      tier,
    } = this.state;

    var verified = "";
    var validated;
    let modal;
    const handleClose = () => {
      this.setState({ modalOpen: false });
    };

    const handleCloseDeclined = () => {
      this.setState({ modalOpen: false });
      this.props.history.push(ROUTES.DECLINED_PAGE);
    };
    const handleCloseDeclinedTier3 = () => {
      this.setState({ modalOpen: false });
      this.props.history.push(ROUTES.DECLINEDUPGRADE_PAGE);
    };

    // let isEmpty = false;
    // let totalShares = 0;
    // myInvestments.forEach((item) => {
    //   totalShares += item.shares;
    // });
    // if (totalShares === 0) isEmpty = true;

    if (kyc !== null) {
      if ("validated" in kyc) validated = kyc.validated;
      else validated = "notValidated";
    } else {
      validated = "notValidated";
    }
    const canAddInvestment =
      parseInt(sessionStorage.getItem("total")) >= 50000 && tier === 2;

    if (validated === "pending") {
      if (tier) {
        if (tier < 2) {
          modal = (
            <div>
              <Dialog
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Application Status"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <b>Hang on!</b> <br />
                    <br />
                    We are currently processing your Client Application. Once
                    it’s approved, we will send you an email, so you can start
                    investing. <br />
                    <br />
                    Note: Client Application approval takes 2-3 business days.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          );
        } else {
          modal = (
            <div>
              <Dialog
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  <span className=" text-lg">
                    Thank you for submitting your application to get Fully
                    Verified!
                  </span>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ textAlign: "center", color: "black" }}
                  >
                    Please keep an eye on your email inbox for the next steps.
                    We may ask to schedule a quick zoom call with you to
                    approve your application as part of AMLA&apos;s
                    guidelines.
                    <p className=" text-green-600 px-5 lg:px-10">
                      In the meantime, you can already invest up to P50,000 in
                      a recommended fund!
                    </p>
                    <p>
                      For any concerns regarding your application, email us at
                      <a
                        href="mailto:online@rampver.com"
                        className=" text-blue-600"
                      >
                        {" "}
                        online@rampver.com
                      </a>
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          );
        }
      }
    } else if (validated === "declined") {
      if (tier < 2) {
        modal = (
          <div>
            <Dialog
              open={modalOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Application Status"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <b>Sorry, we couldn’t approve your Client Application.</b>{" "}
                  <br />
                  <br />
                  Unfortunately, your Client Application was declined due to
                  these possible reasons: <br />
                  <br />
                  1. You have missing information or documents.
                  <br />
                  2. Your personal information does not match the documents you
                  submitted.
                  <br />
                  3. You are ineligible to invest in Rampver Online. Kindly
                  review our Terms and Conditions.
                  <br />
                  <br />
                  Please review our comments on your application. You will be
                  able to rectify your application and resubmit.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeclined} color="primary" autoFocus>
                  Review Application
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      } else {
        modal = (
          <div>
            <Dialog
              open={modalOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Verification Status"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <b>Sorry, we couldn’t approve your Client Verification.</b>{" "}
                  <br />
                  <br />
                  Unfortunately, your Client Verification was declined due to
                  these possible reasons: <br />
                  <br />
                  1. You have missing information or documents.
                  <br />
                  2. Your personal information does not match the documents you
                  submitted.
                  <br />
                  3. You are ineligible to invest in Rampver Online. Kindly
                  review our Terms and Conditions.
                  <br />
                  <br />
                  Please review our comments on your Verification. You will be
                  able to rectify your Verification and resubmit.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDeclinedTier3}
                  color="primary"
                  autoFocus
                >
                  Review Verification
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
    } else {
      modal = "";
    }
    let renderInvestments = "";
    if (smBreakpoint) {
      renderInvestments =
        myInvestments.length !== 0 ? (
          <>
            {investments_show.map((investment) => (
              <Paper
                elevation={4}
                className="investment-container"
                key={investment.fundCode}
                onClick={(e) => {
                  this.handleClickableInvestment(e, {
                    type: 1,
                    data: investment,
                  });
                }}
              >
                <div className="rampver-bgColor investment-display">
                  <span className={classes.suggestedFunds}>
                    {investment.fundName}
                  </span>
                </div>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    xl={2}
                    className="investment-box"
                  >
                    <Grid container style={{ textAlign: "center" }}>
                      <Grid item xs={12}>
                        <b>
                          {parseFloat(
                            investment.shares.toFixed(2)
                          ).toLocaleString()}
                        </b>
                      </Grid>
                      <Grid item xs={12}>
                        <span>Total Shares</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Grid
                      container
                      style={{ textAlign: "center" }}
                      className="investment-box"
                    >
                      <Grid item xs={12}>
                        <b>{investment.currentRate}</b>
                      </Grid>
                      <Grid item xs={12}>
                        <span>NAVPS as of {investment.date}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Grid
                      container
                      style={{ textAlign: "center" }}
                      className="investment-box"
                    >
                      <Grid item xs={12}>
                        <b>{investment.marketValue}</b>
                      </Grid>
                      <Grid item xs={12}>
                        <span>Current Value</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <Grid
                      container
                      style={{ textAlign: "center" }}
                      className="investment-box"
                    >
                      <Grid item xs={12}>
                        <b>{`${this.formatReturns(investment.gain)}%`}</b>
                      </Grid>
                      <Grid item xs={12}>
                        <span>Total Returns</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    className="investment-box"
                  >
                    <Button
                      variant="contained"
                      className={clsx(classes.investmentButton)}
                      onClick={(e) => {
                        this.handleClickableInvestment(e, {
                          type: 2,
                          data: investment,
                        });
                      }}
                      disabled={canAddInvestment}
                    >
                      {
                        <span style={{ fontStyle: "normal", fontSize: "14px" }}>
                          {" "}
                          ADD
                        </span>
                      }
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}

            <br />
            {myInvestments.length > 5 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{
                    fontWeight: 600,
                    border: "black",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                  endIcon={
                    investments_show.length === 5 ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ExpandLessIcon />
                    )
                  }
                  onClick={(e) => {
                    this.setState((prevState) => {
                      const nextIndex = prevState.currentIndex + 0;
                      const newInvestmentsShow =
                        investments_show.length === 5
                          ? prevState.myInvestments.slice(
                            nextIndex,
                            nextIndex + prevState.myInvestments.length
                          )
                          : prevState.myInvestments.slice(
                            nextIndex,
                            nextIndex + 5
                          );
                      return {
                        investments_show:
                          newInvestmentsShow.length > 0
                            ? newInvestmentsShow
                            : prevState.investments_show,
                        currentIndex:
                          newInvestmentsShow.length > 0
                            ? nextIndex
                            : prevState.currentIndex,
                        show_all_inv: !prevState.show_all_inv,
                      };
                    });
                  }}
                >
                  {investments_show.length === 5 ? "See More" : "See Less"}
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className="investment-box-none">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span>You have no investments. Let&apos;s invest again!</span>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  className={classes.investAgain}
                  onClick={(e) => this.startInvest(e)}
                >
                  Invest again
                </Button>
              </Grid>
            </Grid>
          </div>
        );
    } else {
      renderInvestments =
        myInvestments.length !== 0 ? (
          <>
            {investments_show.map((investment) => (
              <>
                <Paper
                  elevation={4}
                  className="investment-container"
                  key={investment.fundCode}
                  onClick={(e) => {
                    this.handleClickableInvestment(e, {
                      type: 1,
                      data: investment,
                    });
                  }}
                >
                  <div className="rampver-bgColor investment-display">
                    <span className={classes.suggestedFunds}>
                      {investment.fundName}
                    </span>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>Total Shares</td>
                        <td>{investment.shares.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td>NAVPS as of {investment.date}</td>
                        <td>{investment.currentRate}</td>
                      </tr>
                      <tr>
                        <td>Current Value</td>
                        <td>{investment.marketValue}</td>
                      </tr>
                      <tr>
                        <td>Total Returns</td>
                        <td>{`${this.formatReturns(investment.gain)}%`}</td>
                      </tr>
                    </tbody>
                  </table>
                  <Button
                    variant="contained"
                    className={clsx(classes.root, classes.thinButton)}
                    fullWidth
                    onClick={(e) => {
                      this.handleClickableInvestment(e, {
                        type: 2,
                        data: investment,
                      });
                    }}
                  >
                    {
                      <span style={{ fontStyle: "normal", fontSize: "14px" }}>
                        {" "}
                        ADD
                      </span>
                    }
                  </Button>
                </Paper>
              </>
            ))}
            <br />
            {myInvestments.length > 3 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{
                    fontWeight: 600,
                    border: "black",
                    fontSize: "14px",
                    textTransform: "none",
                  }}
                  endIcon={
                    investments_show.length === 3 ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ExpandLessIcon />
                    )
                  }
                  onClick={(e) => {
                    this.setState((prevState) => {
                      const nextIndex = prevState.currentIndex + 0;
                      const newInvestmentsShow =
                        investments_show.length === 3
                          ? prevState.myInvestments.slice(
                            nextIndex,
                            nextIndex + prevState.myInvestments.length
                          )
                          : prevState.myInvestments.slice(
                            nextIndex,
                            nextIndex + 3
                          );
                      return {
                        investments_show:
                          newInvestmentsShow.length > 0
                            ? newInvestmentsShow
                            : prevState.investments_show,
                        currentIndex:
                          newInvestmentsShow.length > 0
                            ? nextIndex
                            : prevState.currentIndex,
                        show_all_inv: !prevState.show_all_inv,
                      };
                    });
                  }}
                >
                  {investments_show.length === 3 ? "See More" : "See Less"}
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className="investment-box-none-mobile">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span>You have no investments. Let&apos;s invest again!</span>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  className={classes.investAgain}
                  fullWidth
                  onClick={(e) => this.startInvest(e)}
                >
                  Invest again
                </Button>
              </Grid>
            </Grid>
          </div>
        );
    }

    let buttonTextFirst;
    let buttonFirstDisable = true;
    if (activeStep === 0 && validated === "notValidated") {
      buttonFirstDisable = false;
    }
    if (activeStep === 0 && validated === "notValidated") {
      buttonFirstDisable = false;
    }

    switch (validated) {
      case "notValidated":
        buttonTextFirst = "Proceed";
        break;

      case "pending":
        buttonTextFirst = "For Approval";
        break;

      case "verified":
        buttonTextFirst = "Approved";
        break;

      case "declined":
        buttonTextFirst = "Declined";
        if (this.state.tier !== 1) {
          break;
        }
        verified = (
          <div className="not-verified rampver-errColor">
            <ErrorOutlinedIcon />
            <span>
              We have unfortunately have declined your application.
              <b>
                <Link className="verify" to={ROUTES.DECLINED_PAGE}>
                  {" "}
                  Please review our comments here
                </Link>
              </b>
            </span>
          </div>
        );
        break;

      default:
        break;
    }

    let buttonTextSecond = "N/A";
    let buttonSecondDisable = false;
    if (this.state.user !== null) {
      if (this.state.user.firstTimeInvestor) buttonTextSecond = "Invest Now";
      else {
        buttonTextSecond = "Processing";
        buttonSecondDisable = true;
      }
    }

    const renderSuggestedFunds = suggested_funds.map((fund, index) => (
      <Paper elevation={4} className="suggested-box" key={index}>
        <div className={classes.recommendedInvestCardTitle}>
          <span>{fund.title}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0px",
          }}
        >
          <div className={`indicator ${fund.riskType}`}>{fund.for_agg}</div>
        </div>
        {smBreakpoint ? (
          <div>
            <div className="d-flex">
              <span>{`NAVPS as of ${this.helpers.dateFormat(fund.date)}`}</span>
              <span className={`text-gold`}>{fund.price_per_share}</span>
            </div>
            <div className="d-flex">
              <span>Year-to-Date Return</span>
              <span className={`text-gold`}>{fund.year_to_date}</span>
            </div>
          </div>
        ) : (
          <table>
            <tbody>
              <tr>
                <td>{`NAVPS as of ${this.helpers.dateFormat(fund.date)}`}</td>
                <td className={`text-gold`}>{fund.price_per_share}</td>
              </tr>
              <tr>
                <td>Year-to-Date Return</td>
                <td className={`text-gold`}>{fund.year_to_date}</td>
              </tr>
            </tbody>
          </table>
        )}
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className={classes.recommendedInvestCardButton}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => this.redirectFunds(fund.docId)}
                fullWidth
              >
                <span className={classes.recommendedInvestCardText}>
                  Learn More
                </span>
              </Button>
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className={classes.recommendedInvestCardButton}>
              <Button
                variant="contained"
                color="primary"
                style={{ color: "white" }}
                onClick={() => this.redirectFundPayment(fund.id)}
                fullWidth
              >
                <span className={classes.recommendedInvestCardText}>
                  Invest Now
                </span>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    ));

    let renderSummaryFunds;
    if (smBreakpoint) {
      renderSummaryFunds = (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Paper elevation={3} className="dashboard-bar rampver-bgColor">
              <div className="dashboard-bar-box">
                <FormControl>
                  <FormControlLabel
                    control={
                      myInvestments.length > 1 ? (
                        <b>{`${myInvestments.length} Funds`}</b>
                      ) : (
                        <b>{`${myInvestments.length} Fund`}</b>
                      )
                    }
                    label={<span>Number of Funds</span>}
                    labelPlacement="bottom"
                  />
                </FormControl>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Paper elevation={3} className="dashboard-bar rampver-bgColor">
              <div className="dashboard-bar-box">
                <FormControl>
                  <FormControlLabel
                    control={
                      <b>{this.helpers.formatToPHP(totals[1].number, 2)}</b>
                    }
                    label={<span>Current Portfolio Value</span>}
                    labelPlacement="bottom"
                  />
                </FormControl>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Paper elevation={3} className="dashboard-bar rampver-bgColor">
              <div className="dashboard-bar-box">
                <FormControl>
                  <FormControlLabel
                    control={<b>{`${this.formatReturns(totalGain)}%`}</b>}
                    label={<span>Total Returns</span>}
                    labelPlacement="bottom"
                  />
                </FormControl>
              </div>
            </Paper>
          </Grid>
        </Grid>
      );
    } else {
      renderSummaryFunds = (
        <Paper elevation={3} className="dashboard-bar-mobile rampver-bgColor">
          <Grid container spacing={1}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Grid container>
                <Grid item xs={12}>
                  <div className="dashboard-bar-box-mobile">
                    {myInvestments.length > 1 ? (
                      <b>{`${myInvestments.length} Funds`}</b>
                    ) : (
                      <b>{`${myInvestments.length} Fund`}</b>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="dashboard-bar-box-mobile">
                    <span>Number of Funds</span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Grid container>
                <Grid item xs={12}>
                  <div className="dashboard-bar-box-mobile">
                    <b>{this.helpers.formatToPHP(totals[1].number, 2)}</b>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="dashboard-bar-box-mobile">
                    <span>Current Portfolio Value</span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Grid container>
                <Grid item xs={12}>
                  <div className="dashboard-bar-box-mobile">
                    <b>{`${this.formatReturns(totalGain)}%`}</b>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="dashboard-bar-box-mobile">
                    <span>Total Returns</span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      );
    }

    let name = user === null ? "" : user.firstName;
    if (buttonTextFirst === "For Approval") {
      window.localStorage.removeItem("clientInfo");
      window.localStorage.removeItem("riskProfile");
      window.localStorage.removeItem("marriageOption");
    }
    const approvedLongKYC =
      user === null
        ? false
        : user.approvedLongKYC !== undefined
          ? user.approvedLongKYC
            ? user.stats.tier !== undefined
              ? user.stats.tier === 2
              : false
            : false
          : false;

    const last_tier = user?.stats?.tier === 3;
    if (last_tier) {
      window.localStorage.removeItem("additionalInfo");
      window.localStorage.removeItem("PEPDeclaration");
      window.localStorage.removeItem("FATCA");
    }

    return (
      <>
        {this.state.modalRecurringOpen && (
          <RecurringInvestmentDialog
            userID={this.context.uid}
            fundName={this.state.renderFundName}
            onClose={() => this.setState({ modalRecurringOpen: false })}
            investedAmount={this.state.renderInvested}
            renderProps={this.props}
            minInitialAmount={this.state.renderMinInvested}
            firebase={this.props.firebase}
          />
        )}
        {modal}
        {verified}
        {activeLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Fade in={true}>
            {dashboardStep === 0 ? (
              <div className="body-content">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <h1 className="_title">
                      {`Hello, ${name} `}{" "}
                      {/* {approvedLongKYC &&
                        (tier === 2 &&
                        (validated === "pending" ||
                          validated === "declined") ? (
                          <a
                            href="upgrade-kyc"
                            className="relative p-2 lg:m-5 bottom-1 rounded text-base capitalize bg-gray-300 text-gray-600 cursor-not-allowed"
                            style={{ whiteSpace: "nowrap" }}
                            onClick={(e) => e.preventDefault()}
                          >
                            Processing Your Details
                          </a>
                        ) : (
                          <a
                            href="upgrade-kyc"
                            className="relative p-2 lg:m-5 bottom-1 rounded bg-primary text-base capitalize text-white"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Get Fully Verified
                          </a>
                        ))} */}
                    </h1>
                    <span className="as-of">
                      Welcome to Rampver Online, your easy all-in-one hub for
                      your financial needs!
                    </span>
                    <h3>
                      Here’s how to start your financial journey with Rampver
                      Online!
                    </h3>
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    {lgBreakpoint ? (
                      <Stepper
                        activeStep={activeStep}
                        orientation="vertical"
                        connector={<QontoConnector />}
                      >
                        {this.getSteps().map((label) => (
                          <Step key={label}>
                            <StepLabel style={{ color: "#C8932A" }}>
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    ) : (
                      <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        connector={<FlatConnector />}
                      >
                        {this.getSteps().map((label) => (
                          <Step key={label}>
                            <StepLabel style={{ color: "#C8932A" }}>
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                    <Paper
                      elevation={3}
                      className={
                        "dashboard-bar-steps " +
                        (activeStep === 0
                          ? "rampver-bgColor "
                          : "rampver-bgColorDisabled ") +
                        (smBreakpoint ? "dashboard-bar-flex" : "")
                      }
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                          <span>
                            <b>Complete your Client Application</b> <br />
                            We want to know you, so that we can guide you in
                            your investing journey
                          </span>
                        </Grid>
                        {this.state.tier === 1 && (
                          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <div style={{ marginLeft: "auto" }}>
                              <Button
                                variant="contained"
                                className={clsx(
                                  classes.root,
                                  validated === "declined" && classes.declined
                                )}
                                disabled={buttonFirstDisable}
                                onClick={() => this.handleProceed()}
                                fullWidth
                              >
                                {buttonTextFirst}
                              </Button>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                    <br />
                    <Paper
                      elevation={3}
                      className={
                        "dashboard-bar-steps " +
                        (activeStep === 1
                          ? "rampver-bgColor"
                          : "rampver-bgColorDisabled")
                      }
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                          <span>
                            <b>Start investing in a fund just for you</b> <br />
                            We will recommend funds based on your risk appetite
                            and objectives
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                          <div style={{ marginLeft: "auto" }}>
                            {validated !== "verified" ? (
                              this.state.tier === 2 ? (
                                <div style={{ marginLeft: "auto" }}>
                                  <Button
                                    variant="contained"
                                    className={clsx(classes.root)}
                                    disabled={buttonSecondDisable}
                                    onClick={() => this.handleInvest(false)}
                                    fullWidth
                                  >
                                    {buttonTextSecond}
                                  </Button>
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              <div style={{ marginLeft: "auto" }}>
                                <Button
                                  variant="contained"
                                  className={clsx(classes.root)}
                                  disabled={buttonSecondDisable}
                                  onClick={() => this.handleInvest(false)}
                                  fullWidth
                                >
                                  {buttonTextSecond}
                                </Button>
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Paper>
                    <br />
                    <Paper
                      elevation={3}
                      className={
                        "dashboard-bar-steps " +
                        (activeStep === 2
                          ? "rampver-bgColor"
                          : "rampver-bgColorDisabled")
                      }
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                          <span>
                            <b>
                              Keep investing to achieve your financial goals!
                            </b>{" "}
                            <br />
                            {activeStep < 2
                              ? `We will guide you towards disciplined investing to fulfill your life goals`
                              : `We will notify you once your first investment has been processed`}
                          </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                          {activeStep < 2 ? (
                            ""
                          ) : (
                            <div style={{ marginLeft: "auto" }}>
                              <Button
                                variant="contained"
                                className={clsx(
                                  classes.root,
                                  classes.less_padding
                                )}
                                disabled={activeStep === 2 ? false : true}
                                onClick={() => this.handleInvest(true)}
                                fullWidth
                              >
                                Diversify your portfolio
                              </Button>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
                {tier <= 2 && (
                  <>
                    <br />
                    <h2>Rampver Learning Center</h2>
                    <DashboardLearningCenter />
                  </>
                )}
              </div>
            ) : (
              <div className="body-content">
                <h1 className="_title">
                  {`Hello, ${name}`}
                  {/* {approvedLongKYC &&
                    (tier === 2 &&
                    (validated === "pending" || validated === "declined") ? (
                      <a
                        href="upgrade-kyc"
                        className="relative p-2 lg:m-5 bottom-1 rounded text-base capitalize bg-gray-300 text-gray-600 cursor-not-allowed"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        Processing Your Details
                      </a>
                    ) : (
                      <a
                        href="upgrade-kyc"
                        className="relative p-2 lg:m-5 bottom-1 rounded bg-primary text-base capitalize text-white"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Get Fully Verified
                      </a>
                    ))} */}
                </h1>
                <span className="as-of">It’s a great day to invest!</span>
                <br />
                <br />
                <Divider />
                <br />
                <br />
                {renderSummaryFunds}
                <br />
                <div className="my-investments">
                  <div className="title">
                    <h2>
                      My Investments <br />
                      <span>{`Account ${user.accountNumber}`}</span>
                    </h2>
                  </div>
                </div>
                {renderInvestments}

                <div className="my-investments">
                  <div className="title">
                    <h2>My Investment Performance</h2>
                  </div>
                </div>
                {this.getInvestmentPerfDisplay()}
                {/* {isEmpty ? ""
                      : (
                        <Paper elevation={3} className="content-margin">
                          <div>
                            <Pie
                              redraw={pieRedraw}
                              data={pie_chart_data}
                              options={pie_chart_options}
                              height={140}
                            />
                          </div>
                        </Paper>
                      )} */}

                <br />
                {suggested_funds.length !== 0 ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className="suggested-funds">
                        <div className="title">
                          <h2>Investments just for you!</h2>
                          <span>
                            These funds are recommended based on your risk
                            profile and objectives
                          </span>
                        </div>{" "}
                        <br /> <br />
                        <div className="suggested-list">
                          {renderSuggestedFunds}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </div>
            )}
          </Fade>
        )}
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const Dashboard = compose(
  withAuthorization(condition),
  withFirebase
)(DashboardBase);

export default DashboardPage;

export { Dashboard };
