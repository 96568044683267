
import UBLogo from "../../../assets/paymentOptions/UB.png";
import InstaPayLogo from "../../../assets/paymentOptions/instapay.png";
import PesoNetLogo from "../../../assets/paymentOptions/pesonet.png";
import GCashLogo from "../../../assets/paymentOptions/gcash.png";
import MayaLogo from "../../../assets/paymentOptions/maya.png";
import PesoLogo from "../../../assets/paymentOptions/peso.png";
import BPILogo from "../../../assets/paymentOptions/bpi-logo.png";
import GcashSample from "../../../assets/paymentOptions/sampleimage/GCash_Payment_Confirmation.png";
import UBQR from "../../../assets/paymentOptions/Rampver_Financials_Unionbank_QR_2025.jpg";

 const data = {
   payment_options: [
     {
       name: "Unionbank App",
       img: UBLogo,

       details: {
         description:
           "You can easily pay for your Rampver Online investment with your Unionbank App!",
         steps: [
           {
             order: 1,
             instruction:
               "On your Unionbank App dashboard, click <b>Send Money</b>",
           },
           {
             order: 2,
             instruction:
               "Among the options, choose <b>Another UnionBank Account</b>",
           },
           {
             order: 3,
             instruction:
               "From here, you can input Rampver Financials' Unionbank Account number or scan the QR Code:",
           },
           {
             order: 4,
             instruction: "Input Rampver Financials' Bank Details:",
           },
           {
             order: 5,
             instruction:
               "Input the <b>Total Amount to be Paid</b> for your Rampver Online Investment",
           },
           {
             order: 6,
             instruction: "Click <b>Transfer Amount</b> to send your payment",
           },
         ],
       },
       attention:
         "Do not forget to screenshot the confirmation page as proof of your payment.",
       note: null,
       selected: false,
       qrcode: true,
       sampleimg: null,
       qrCustom: UBQR
     },
    // {
     //    name: "BPI App",
     //    img: BPILogo,

     //    details: {
     //      description:
     //        "You can easily pay for your Rampver Online investment with your BPI Mobile App!",
     //      steps: [
     //        {
     //          order: 1,
     //          instruction:
     //            "Open your BPI mobile app or visit <a href=https://online.bpi.com.ph/ target=_blank> <u>https://online.bpi.com.ph/ </u></a> to log in.",
     //        },
     //        {
     //          order: 2,
     //          instruction:
     //            "Tap <b>More</b> > <b>General Settings</b> > <b>Manage Favorites</b> > <b>+ Add new Favorite</b>",
     //        },
     //        {
     //          order: 3,
     //          instruction:
     //            "For the <b>Select type of Favorite</b> section, select <b>Billers</b>",
     //        },
     //        {
     //          order: 4,
     //          instruction:
     //            "For the <b>Select biller</b> section, search and select <b>Rampver Online | RFIONL </b>",
     //        },
     //        {
     //          order: 5,
     //          instruction:
     //            "For the <b>Reference number</b> section, enter the numeric digits of your Rampver Online account number (RFXXXXXXXXXX) and click <b>Continue</b>.",
     //        },
     //        {
     //          order: 6,
     //          instruction:
     //            "Ensure your details are correct then click <b>Confirm</b>",
     //        },
     //        {
     //          order: 7,
     //          instruction:
     //            "To proceed, a one-time PIN will be sent to you via SMS. Click <b>Yes, send me the code</b>.",
     //        },
     //        {
     //          order: 8,
     //          instruction:
     //            "Enter the 6-digit code sent to your BPI registered mobile number and click <b>Confirm</b>. <p/><i> Note: If you did not receive a 6-digit code, you can select <b>Resend code via SMS</b></i>",
     //        },
     //        {
     //          order: 9,
     //          instruction:
     //            "Once you have successfully added Rampver Online as your favorite biller, click <b>Pay Bills</b>",
     //        },
     //        {
     //          order: 10,
     //          instruction:
     //            "Click <b>Pay from</b> and select your preferred BPI account for your investment",
     //        },
     //        {
     //          order: 11,
     //          instruction:
     //            "Click <b>Pay to</b> and select <b>RFIONL</b>. <p/> <i>Note: <b>RFIONL</b> is the the code name of Rampver Online as a BPI biller </ui>",
     //        },
     //        {
     //          order: 12,
     //          instruction:
     //            "Input the Total Amount to be Paid for your Rampver Online Investment and click <b>Continue</b>",
     //        },
     //      ],
     //    },
     //    attention:
     //      "Do not forget to screenshot the confirmation page as proof of your payment.",
     //    note: "Note: If you have already added Rampver Online under your list of favorite billers, proceed to <b>Step 9</b>.",
     //    selected: false,
   // },
     {
       name: "GCash QR",
       img: GCashLogo,

       details: {
         description:
          `You can easily pay for your Rampver Online investment with your Gcash App!`,
         steps: [
           {
             order: 1,
             instruction:
               "On your GCash App dashboard, click <b>QR</b> found at the bottom bar.",
           },
           {
             order: 2,
             instruction:
               "Scan the Rampver Financials <b>QR Code</b> displayed on screen OR take a screenshot and upload it.",
           },
           {
             order: 3,
             instruction:
               "Input the <b>Total Amount to be Paid</b> for your Rampver Online Investment.",
           },
           {
             order: 4,
             instruction: "Click <b>Send Money</b> to send your payment",
           },
           {
             order: 5,
             instruction:
               "Take a screenshot of your <b>confirmation details found in your GCash Inbox</b> (or you may use the email confirmation). It must include the reference number, amount, date, and time of the transaction.",
           },
         ],
       },
       attention:
         "Do not forget to screenshot the confirmation page as proof of your payment.",
       note: "Note: We cannot validate your payment if your confirmation screenshot displays ”Bank Transfer is being processed.”",
       selected: false,
       qrcode: true,
       sampleimg: GcashSample,
     },
     {
      name: "GCash Bank Transfer",
      img: GCashLogo,

      details: {
        description:
         `You can easily pay for your Rampver Online investment with your Gcash App!`,
        steps: [
          {
            order: 1,
            instruction:
              "On your GCash App dashboard, click <b>Transfer</b> found right below your wallet balance",
          },
          {
            order: 2,
            instruction:
              "Choose <b>Unionbank</b> among the select GCash Partner Banks",
          },
          {
            order: 3,
            instruction:
              "Input the <b>Total Amount to be Paid</b> for your Rampver Online Investment.",
          },
          {
            order: 4,
            instruction: "Input Rampver Financials' Bank Details:",
          },
          {
            order: 5,
            instruction:
              "Click <b>Send Money</b> to send your payment",
          },
          {
            order: 6,
            instruction:
              "Take a screenshot of your <b>confirmation details found in your GCash Inbox</b> (or you may use the email confirmation). It must include the reference number, amount, date, and time of the transaction.",
          },
        ],
      },
      attention:
        "Do not forget to screenshot the confirmation page as proof of your payment.",
      note: "Note: We cannot validate your payment if your confirmation screenshot displays ”Bank Transfer is being processed.”",
      selected: false,
      qrcode: false,
      sampleimg: GcashSample,
    },
    //  {
    //    name: "Maya",
    //    img: MayaLogo,

    //    details: {
    //      description:
    //        "You can easily pay for your Rampver Online investment with your Maya App!",
    //      steps: [
    //        {
    //          order: 1,
    //          instruction:
    //            "On your <b>Maya App dashboard</b>, click <b>QR Icon</b> found at the bottom bar.",
    //        },
    //        {
    //          order: 2,
    //          instruction:
    //            "Scan the <b>Rampver Financials QR Code</b> displayed on screen or take a screenshot and upload it.",
    //        },
    //        {
    //          order: 3,
    //          instruction:
    //            "Input the <b>Total Amount to be Paid</b> for your Rampver Online Investment.",
    //        },
    //        {
    //          order: 4,
    //          instruction:
    //            "Click <b>Continue</b> to review your payment details.",
    //        },
    //        {
    //          order: 5,
    //          instruction: "Click <b>Pay</b> to send your payment.",
    //        },
    //        {
    //          order: 6,
    //          instruction:
    //            "Take a <b>screenshot of your confirmation details</b>. It must include the reference number, date, and time of the transaction.",
    //        },
    //      ],
    //    },
    //    attention:
    //      "Do not forget to screenshot the confirmation page as proof of your payment.",
    //    note: null,
    //    selected: false,
    //    qrcode: true,
    //    sampleimg: null,
    //  },
     {
       name: "Any Bank or E-Wallet via InstaPay",
       img: InstaPayLogo,

       details: {
         description:
           "You can easily pay for your Rampver Online investment with any bank that supports InstaPay! See full list <a href='https://www.instapayph.com/what-is-instapay/#instapaypartners' target='_blank'>here</a>",
         steps: [
           {
             order: 1,
             instruction:
               "On your Bank's App, select the <b>Bank Transfer or Send Money</b> option",
           },
           {
             order: 2,
             instruction:
               "Among the bank options, select <b>Unionbank (or Union Bank of the Philippines)</b>",
           },
           {
             order: 3,
             instruction:
               "Input the <b>Total Amount to be Paid</b> for your Rampver Online Investment",
           },
           {
             order: 4,
             instruction: "Input Rampver Financials' Bank Details or scan the QR Code: ",
           },
           {
             order: 5,
             instruction:
               "*For some Bank Apps, you will need to select <b>Instapay</b> as the Transfer Service",
           },
           {
             order: 6,
             instruction:
               "Follow your Bank App's instructions to finalize and send the payment",
           },
         ],
       },
       attention:
         "Do not forget to screenshot the confirmation page as proof of your payment.",
       note: "Note: Your bank may charge transaction fees for InstaPay payments. Maximum amount per transaction is Php 50,000",
       selected: false,
       qrcode: true,
       sampleimg: null,
     },
     {
       name: "Any Bank via PESONet",
       img: PesoNetLogo,
       qrCode: null,
       details: {
         description:
           "You can easily pay for your Rampver Online investment with any bank that supports PESONet! See full list <a href='https://www.bsp.gov.ph/PaymentAndSettlement/PESONet%20Participants.pdf' target='_blank' rel='noopener noreferrer'><u>here</u></a>",
         steps: [
           {
             order: 1,
             instruction:
               "On your Bank's App, select the <b>Bank Transfer or Send Money</b> option",
           },
           {
             order: 2,
             instruction:
               "Among the bank options, select <b>Unionbank (or Union Bank of the Philippines)</b>",
           },
           {
             order: 3,
             instruction:
               "Input the <b>Total Amount to be Paid</b> for your Rampver Online Investment",
           },
           {
             order: 4,
             instruction: "Input Rampver Financials' Bank Details or scan the QR Code:",
           },
           {
             order: 5,
             instruction:
               "*For some Bank Apps, you will need to select <b>PESONet</b> as the Transfer Service",
           },
           {
             order: 6,
             instruction:
               "Follow your Bank App's instructions to finalize and send the payment",
           },
         ],
       },
       attention:
         "Do not forget to screenshot the confirmation page as proof of your payment.",
       note: "Note: Your bank may charge transaction fees for PESONet payments. The maximum amount per transaction may differ from bank to bank.",
       selected: false,
       qrcode: true,
       sampleimg: null,
     },
     {
       name: "Unionbank Over-The-counter (OTC)",
       img: PesoLogo,
       qrCode: null,
       details: {
         description:
           "You can easily pay for your Rampver Online investment at any Unionbank branch nationwide!",
         steps: [
           {
             order: 1,
             instruction:
               "Input the <b>Total Amount to be Paid</b> for your Rampver Online Investment on your deposit slip",
           },
           {
             order: 2,
             instruction:
               "Input Rampver Financials' Bank Details:",
           },
           {
            order: 1,
            instruction:
              "Deposit the <b>Total Amount to be Paid</b> for your Rampver Online Investment at any Unionbank branch nationwide.",
          },
         ],
       },
       attention: "Take a photo of your deposit slip as proof of your payment.",
       note: null,
        selected: false,
       qrcode: false,
       sampleimg: null,
     },
   ],
   bank_details: {
     account_number: "002130009962",
     account_name: "RAMPVER FINANCIALS INC",
   },
 };
export default data




